import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Grid,
    TextField,
    IconButton,
    Box , Container , Stack, Typography , Autocomplete, Pagination
  } from "@mui/material";

export default function MarkDownDialog({openDialog, setOpenDialog}) {


  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
         MarkDown Instructions
        </DialogTitle>
        <DialogContent>
        <Box width='100%'>
                <Stack spacing={3} mt={2}>
                    <Stack spacing={1} py={1} borderBottom='1px solid rgba(0,0,0,0.1)'>
                      <Typography fontWeight={600}>1. For Heading</Typography>
                      <Typography>use #</Typography>
                      <Typography><strong>Example:</strong> # A demo of react-markdown</Typography>
                    </Stack>

                    <Stack spacing={1} py={1} borderBottom='1px solid rgba(0,0,0,0.1)'>
                      <Typography fontWeight={600}>2. For Sub Heading</Typography>
                      <Typography>use ##</Typography>
                      <Typography><strong>Example:</strong> ## Overview</Typography>
                    </Stack>


                    <Stack spacing={1} py={1} borderBottom='1px solid rgba(0,0,0,0.1)'>
                      <Typography fontWeight={600}>3. For List Items</Typography>
                      <Typography>use * (NOTE : There should be Gap between * and Text)</Typography>
                      <Typography><strong>Example:</strong>* Has a lot of plugins</Typography>
                    </Stack>


                    <Stack spacing={1} py={1} borderBottom='1px solid rgba(0,0,0,0.1)'>
                      <Typography fontWeight={600}>4. For Links</Typography>
                      <Typography><strong>Example:</strong>Follows [CommonMark](https://commonmark.org)</Typography>
                      <Typography>Anchor text should be in a square bracket and and the link for the anchor text will be in another bracket.</Typography>
                      <Typography>This How It Will Render on Display :</Typography>
                      <Typography>Follows <a style={{textDecoration:'none' , color:"#135AD8"}}>CommonMark</a></Typography>
                    </Stack>


                    <Stack spacing={1} py={1} borderBottom='1px solid rgba(0,0,0,0.1)' pb={2}>
                      <Typography fontWeight={600}>2. For Thick Border</Typography>
                      <Typography>use ***</Typography>
                      <Typography><strong>Example:</strong>***</Typography>
                    </Stack>

                </Stack>
               </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}