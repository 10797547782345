import { useState } from "react";
import { Box, Grid, IconButton, InputAdornment, Stack , Typography, Container, TextField, Button } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import axios from "axios";
import { login, methodPost } from "../../Api/Api";
import { startUrl } from "../../Routes";
import {AES} from "crypto-js"
import SnackBar from "../../components/SnackBar";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import loginLogo from '../../images/login-logo.png';


export default function Login({ setsuccessOpen, setsuccessMessage, setsuccessStatus, setsuccessColor }) {
   const [showPassword, setShowPassword] = useState(true);
   const navigate = useNavigate();
   const [open, setOpen] = useState(false);
   const [status, setStatus] = useState(false);
   const [color, setColor] = useState(false);
   const [message, setMessage] = useState("");
   const[email, setEmail] = useState('');
   const[password, setPassword]= useState('');
   const [errMsg , setErrMsg] = useState('');


   function Copyright(props) {
    return (
      <Typography variant="body2" color="white" align="center" {...props}>
      {'Copyright © '}
      <a href="" style={{ color: '#FFDF15' }} target="_blank" rel="noopener noreferrer">
       Professional Editing Tools
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
    );
  }

   const handleSubmit = (e) => {
    e.preventDefault();
    if(email !== "" && password !== ""){
      const serverData = new FormData()
      serverData.append('userName', email);
      serverData.append('userPassword',password);
        if (!navigator.onLine) {
          setMessage('Your internet is in Offline')
          setOpen(true)
          setStatus(false)
          setColor(false)
        } else {
          axios({
            method: methodPost,
            url: login,
            data: serverData,
          }).then(res => {
            if (res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(false)
              setColor(false)
            } else {
              localStorage.setItem('AdminPETAuth', true);
              localStorage.setItem('AdminPETUserId', res.data.data.userId);
              navigate(`${startUrl}app`)
              setsuccessOpen(true)
              setsuccessMessage(res.data.message)
              setsuccessStatus(true)
              setsuccessColor(true)

            }
          }).catch(err => {
            alert('Oops something went wrong' + err)
          });
        }
      }
      }

  return (

    <Box className='LoginPage'>
       <Grid container display={'flex'} justifyContent={'center'}>
        <Grid item lg={8} xs={12}>
        <Grid container>
      <Grid item xs={12} sm={6} lg={6} xl={6} className="login-container">
    <Container component="main" >
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

    <Box
    className='FirstSection'
    sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color:'white'
    }}
    >

    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} px={5} pt={3}>

    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
    <Typography component="h1" variant="h5">
    Sign in
    </Typography>
    </div>
    <Stack textAlign='left'>
    <Box>
    <Typography>Email</Typography>
    <TextField
    margin="normal"
    required
    fullWidth
    id="email"
    name="email"
    type='email'
    autoComplete="email"
    onChange={(e)=>setEmail(e.target.value)}
    className="login-textfield"
    />
    </Box>
    <Box>
    <Typography>Password</Typography>
    <TextField
    margin="normal"
    required
    fullWidth
    name="password"
    type="password"
    id="password"
    autoComplete="current-password"
    onChange={(e)=>setPassword(e.target.value)}
    helperText={errMsg}
    className="login-textfield"
    />
    </Box>
    </Stack>
    <Button
    type="submit"
    fullWidth
    variant="contained"
    sx={{ mt: 1, mb: 1, bgcolor:'#CECECE', color:'black', ':hover':{bgcolor:'#3F36CA', color:'white'} }}
    ><b>
    Sign In</b>
    </Button>
    </Box>
    <Copyright sx={{ my:2 }} />

    </Box>




    </Container>


    </Grid>
    <Grid item xs={12} sm={6} lg={6} xl={6} className="login-right-container">
    <img
                        src={loginLogo}

                        alt="professional-editing-tools"
                        width={'100%'}
                      />
    </Grid>
    </Grid>
    </Grid>
    </Grid>
    </Box>

  );
}