import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { add_faq, add_product, delete_faq, get_faq, get_home_section1, get_home_section2, get_product, get_testimonial, update_faq, update_home_section1, update_home_section2, update_product } from "../../Api/Api";
import SnackBar from "../../components/SnackBar";
import Heading from "../../components/Heading/Heading";

export default function NewFeature({product}) {

   const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);

  const [newData, setNewData] = useState({
    title: "",
    content:"",
    images:null,
  });

  const [newImage, setNewImage] = useState(null);



  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedImage(newImage);
  };



  const AddNew = () =>{
    if(newData.title !== '' && newData.content !== '' && newImage !== null) {
    const serverData = new FormData()
    serverData.append("title", newData.title)
    serverData.append("content", newData.content)
    serverData.append("images", newImage)
    serverData.append('productName' , product)
    axios({
      method: "POST",
      url: add_product,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setNewImage(null);
        document.getElementById('newsliderImage').value ='';
        setNewData({
            title: "",
            content:"",
            images:null,
          });
          window.location.reload()
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }
    else{
      setOpen(true)
        setStatus(false)
        setColor(false)
      setMessage('Fill all the Fields');
    }
  }

  

  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

      <Container>
      <Box  mt={5} mb={2}>
        <Heading title={`Add New ${product} Feature`} />
      </Box>

        <Grid container spacing={2}>

                <Grid item xs={12} sm={12} md={12} lg={7} display='flex' justifyContent='start'>
                <TextField
                label="Title"
                fullWidth
                variant="outlined"
                value={newData.title}
                onChange={(e) =>
                    setNewData({ ...newData, title: e.target.value })
                }
                />
                </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={7} display='flex' justifyContent='start'>
                    <TextField
                    label="Content"
                    fullWidth
                    multiline
                    rows={8}
                    variant="outlined"
                    value={newData.content}
                    onChange={(e) =>
                    setNewData({ ...newData, content: e.target.value })
                    }
                    />
                    </Grid>

                <Grid item xs={12} sm={12} md={12} lg={7} display='flex' justifyContent='start'>
                <TextField
                type="file"
                fullWidth
                id='newsliderImage'
                onChange={(e)=>setNewImage(e.target.files[0])}
                inputProps={{ accept: 'image/*' }}
                sx={{ marginTop: 2 }}
                />
                </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} display='flex' justifyContent='start'>
        <Box display='flex' flexDirection='row'> 
          <Button 
          variant="contained" onClick={AddNew}>Generate</Button>
        </Box>
        </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
