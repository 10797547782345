import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import axios from 'axios';
import StudentSection1 from './StudentLayout/StudentSection1';
import StudentSection2 from './StudentLayout/StudentSection2';
import StudentSection3 from './StudentLayout/StudentSection3';
import StudentSection4 from './StudentLayout/StudentSection4';



export default function Student() {

    const [value, setValue] = React.useState(0);
    const [HomeSilder, setHomeSlider] = useState([])
    const [OfferSection, setOfferSection] = useState([])
    const [BrandSection, setBrandSection] = useState([])
    const [CategorySection, setCategorySection] = useState([])
    const [ArrivingSection, setArrivingSection] = useState([])
    const [categoryList, setCategoryList] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 2 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }


    return (
        <Container>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={4} mb={1}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="success"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="full width tabs example"
                >
                    <Tab
                        label="Section 1"
                        sx={{
                            bgcolor: value === 0 && '#135AC8',
                            color: value === 0 ? '#fff' : '#000',
                            border: value === 0 && '1px solid silver',
                            borderRadius: value === 0 && '4px',
                            textTransform: 'none'
                        }}
                        {...a11yProps(0)} />
                    <Tab
                        label="Section 2"
                        sx={{
                            bgcolor: value === 1 && '#135AC8',
                            color: value === 1 ? '#fff' : '#000',
                            border: value === 1 && '1px solid silver',
                            borderRadius: value === 1 && 1,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Section 3"
                        sx={{
                            bgcolor: value === 2 && '#135AC8',
                            color: value === 2 ? '#fff' : '#000',
                            border: value === 2 && '1px solid silver',
                            borderRadius: value === 2 && 2,
                            textTransform: 'none'
                        }}
                        {...a11yProps(2)}
                    />     
                          <Tab
                        label="Section 4"
                        sx={{
                            bgcolor: value === 3 && '#135AC8',
                            color: value === 3 ? '#fff' : '#000',
                            border: value === 3 && '1px solid silver',
                            borderRadius: value === 3 &&3,
                            textTransform: 'none'
                        }}
                        {...a11yProps(3)}
                    />                              
                </Tabs>
            </Box>

           

            <TabPanel value={value} index={0} >
              <StudentSection1 />
            </TabPanel>

           
            <TabPanel value={value} index={1}>
            <StudentSection2 />
            </TabPanel>

            <TabPanel value={value} index={2}>
            <StudentSection3 />
            </TabPanel>

            <TabPanel value={value} index={3}>
            <StudentSection4 />
            </TabPanel>



        </Container>
    )
}
