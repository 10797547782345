import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import SnackBar from "../../../components/SnackBar";
import { get_student1, update_student1} from "../../../Api/Api";


export default function StudentSection1() {

   const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);

  const [editedData, setEditedData] = useState({
  title: "",
  content:"",
  images:null,
  });

  const [newData, setNewData] = useState({
    title: "",
    content:"",
    images:null,
  });

  const [newImage, setNewImage] = useState(null);

  const handleEditClick = (id) => {
    setImageId(id);
    setEditMode(true);
    getParticularData(id);
  };

  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedImage(newImage);
  };


  const getAllData = () => {
    const sendData = new FormData()
    sendData.append('studentId1', '');
    axios({
      method: 'POST',
      url: get_student1,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data[0])
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllData();
  }, []);



  const getParticularData = (id) => {
    if(id !== "" && id !== undefined){
    const sendData = new FormData()
    sendData.append('studentId1', id);
    axios({
      method: 'POST',
      url: get_student1,
      data: sendData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setEditedData({
          title: res.data.data.title,
          content:res.data.data.content,
          images:res.data.data.images,
        })
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }
  }

  useEffect(() => {
    getParticularData();
  }, [imageId])



  const handleClick = () => {
    const serverData = new FormData()
    serverData.append("title", editedData.title)
    serverData.append("content", editedData.content)
    serverData.append("studentId1", imageId)
    serverData.append("images", selectedImage)
    axios({
      method: "POST",
      url: update_student1,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setImageId('');
        getAllData();
        setSelectedImage(null);
        setEditMode(false);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  const Cancel = () => {
    setSelectedImage(null);
    setEditMode(false);
  }


  

  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

      <Container>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} key={data?.studentId1}>
              {editMode && imageId === data?.studentId1 ? (
                <Stack spacing={1}>
                   <TextField
                    label="Title"
                    fullWidth
                    multiline
                    rows={2}
                    variant="outlined"
                    value={editedData.title}
                    onChange={(e) =>
                      setEditedData({ ...editedData, title: e.target.value })
                    }
                  />

                  <TextField
                  label="Content"
                  fullWidth
                  multiline
                  rows={8}
                  variant="outlined"
                  value={editedData.content}
                  onChange={(e) =>
                  setEditedData({ ...editedData, content: e.target.value })
                  }
                  />

                  <TextField
                    type="file"
                    onChange={handleImageChange}
                    inputProps={{ accept: "image/*" }}
                    sx={{ marginTop: 2 }}
                  />

                  <Box display='flex' flexDirection='row'> 
                    <Button onClick={handleClick}>Update</Button>
                    <Button onClick={Cancel} color='error'>Cancel</Button>
                  </Box>
                </Stack>

              ) : (
                  <Stack>
                    <Box display='flex' flexDirection='row' alignContent='center' gap={1}>
                    <img
                        src={data?.images}
                        style={{
                          width: 200,
                          height: 200,
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                      <Box display='flex' flexDirection='column' p={1}>
                        <IconButton
                          onClick={() => handleEditClick(data?.studentId1)}
                          color="primary"
                          sx={{ marginTop: 1 }}
                        >
                          <EditIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                      </Box>
                    </Box>
                    <Stack spacing={1} textAlign='left' mt={1}>
                    <Typography variant='subtitle2'><strong>Title: </strong>{data?.title}</Typography>
                      <Typography variant='subtitle2'><strong>Content: </strong>{data?.content}</Typography>
                    </Stack>
                  </Stack>
              )}
            </Grid>

        </Grid>
      </Container>
    </Box>
  )
}
