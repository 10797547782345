import React, { useState } from 'react'
import {  Navigate, useRoutes } from 'react-router-dom'
import Layout from './Layout';
import Login from './Pages/Login/Login';
import WelcomePage from './Pages/WelcomePage';
import WebsiteTab from './Pages/Home/HomePageTab';
import POETTab from './Pages/Products/POETTab';
import ContractTab from './Pages/Products/ContractTab';
import About from './Pages/About/About';
import Individuals from './Pages/ForWork/Individuals';
import Teams from './Pages/ForWork/Teams';
import Success from './Pages/ForWork/Success';
import Events from './Pages/ForWork/Events';
import Student from './Pages/ForEducation/Student';
import Institute from './Pages/ForEducation/Institute';
import Educationevent from './Pages/ForEducation/Educationevent';
import BlogTab from './Pages/Blog/BlogTab';
import NewsTab from './Pages/News/NewsTab';
import Query from './Pages/Query/Query';


// this is pages

// this variable is for starting url
//this variable is for nadi local development 
// export const startUrl = `/nadipms/`;

//this variable is for local development 
export const startUrl = `/`;

// this variable for testing server
// export const startUrl = `/nadi/testing/`;

// this variable is for live server
// export const startUrl = `/nadi/web/`;

function Routes() {
   const auth = localStorage.getItem('AdminBoundauth');
   const [successOpen, setsuccessOpen] = useState(false);
   const [successMessage, setsuccessMessage] = useState('');
   const [successStatus, setsuccessStatus] = useState(false);
   const [successColor, setsuccessColor] = useState(false);


    return useRoutes([
      {
      path: startUrl,
      element: <Login   setsuccessOpen={setsuccessOpen} setsuccessMessage={setsuccessMessage} setsuccessStatus={setsuccessStatus} setsuccessColor={setsuccessColor} />
      },
      {
      path: `${startUrl}app`,
      element: !auth ? <Layout   successOpen={successOpen} setsuccessOpen={setsuccessOpen} successMessage={successMessage} successStatus={successStatus} successColor={successColor} /> : <Navigate to={startUrl} />,
      children:[
      {
      path: `${startUrl}app`,
      element: <Navigate to ={`home`} />,
      },

      {
      path: 'home',
      element: <WelcomePage />,
      },
      {
        path:'homepage',
        element: <WebsiteTab />,
      },
      {
        path:'poet',
        element: <POETTab />,
      },
      {
        path:'about',
        element: <About />,
      },
      {
        path:'contract',
        element: <ContractTab />,
      },

      {
        path:'individual',
        element: <Individuals />,
      },

      {
        path:'team',
        element: <Teams />,
      },

      {
        path:'success',
        element: <Success />,
      },

      {
        path:'workevent',
        element: <Events />,
      },

      {
        path:'student',
        element: <Student />,
      },

      {
        path:'institution',
        element: <Institute />,
      },

      {
        path:'educationevent',
        element: <Educationevent />,
      },
      {
        path: 'blog',
        element: <BlogTab />,
        },
        {
          path: 'news',
          element: <NewsTab />,
          },
          {
            path: 'query',
            element: <Query />,
            },

{ path: `logout`, element: <Navigate to={startUrl} /> },

] 
}
    ])
}

export default Routes;