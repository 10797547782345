import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { add_faq, delete_faq, get_contact_us, get_faq, get_home_section1, get_testimonial, update_contact_us, update_faq, update_home_section1 } from "../../Api/Api";
import SnackBar from "../../components/SnackBar";
import Heading from "../../components/Heading/Heading";

export default function Contact() {

   const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);
  const [error , setError] = useState(false)
  const [errorMSG , setErrorMSG] = useState('')

  const [editedData, setEditedData] = useState({
    contactAddress: "",
    contactPhone: "",
    contactMail:"",
    mapLink:"",
  });

  const [newData, setNewData] = useState({
    contactAddress: "",
    contactPhone: "",
    contactMail:"",
    mapLink:"",
  });

  const [newImage, setNewImage] = useState(null);

  const handleEditClick = (id) => {
    setImageId(id);
    setEditMode(true);
    getParticularData(id);
  };

  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedImage(newImage);
  };


  const getAllData = () => {
    const sendData = new FormData()
    sendData.append('contactUsId', '');
    axios({
      method: 'POST',
      url: get_contact_us,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllData();
  }, []);



  const getParticularData = (id) => {
    if(id !== "" && id !== undefined){
    const sendData = new FormData()
    sendData.append('contactUsId', id);
    axios({
      method: 'POST',
      url: get_contact_us,
      data: sendData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setEditedData({
            contactAddress: res.data.data.contactAddress,
            contactPhone: res.data.data.contactPhone,
            contactMail: res.data.data.contactMail,
            mapLink: res.data.data.mapLink,
        })
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }
  }

  useEffect(() => {
    getParticularData();
  }, [imageId])



  const handleClick = () => {
    const serverData = new FormData()
    serverData.append("contactMail", editedData.contactMail)
    serverData.append("contactAddress", editedData.contactAddress)
    serverData.append("contactUsId", imageId)
    serverData.append("mapLink", editedData.mapLink)
    serverData.append("contactPhone", editedData.contactPhone)
    axios({
      method: "POST",
      url: update_contact_us,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setImageId('');
        getAllData();
        setSelectedImage(null);
        setEditMode(false);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  const Cancel = () => {
    setSelectedImage(null);
    setEditMode(false);
  }



  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

    <Container>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} key={data?.contactUsId}>
              {editMode && imageId === data?.contactUsId ? (
                <Stack spacing={1}>
                <TextField
                label="Address"
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                InputLabelProps={{
                    shrink:true
                }}
                value={editedData.contactAddress}
                onChange={(e) =>
                setEditedData({ ...editedData, contactAddress: e.target.value })
                }
                />

                <TextField
                label="Phone"
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                error={error}
                helperText={errorMSG}
                InputLabelProps={{
                    shrink:true
                }}
                inputProps={{
                    maxLength:13
                }}
                value={editedData.contactPhone}
                onChange={(e) =>{
                if (e.target.value.match(/^[0-9]{4,13}$/) || e.target.value === '') {
                setEditedData({ ...editedData, contactPhone: e.target.value })
                setError(false)
                setErrorMSG('');
                }
                else{
                    setError(true)
                    setErrorMSG(`Invalid Number`)
                }
                }}
                />


                <TextField
                label="Email"
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                InputLabelProps={{
                    shrink:true
                }}
                value={editedData.contactMail}
                onChange={(e) =>
                setEditedData({ ...editedData, contactMail: e.target.value })
                }
                />

                <TextField
                label="Map"
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                InputLabelProps={{
                    shrink:true
                }}
                value={editedData.mapLink}
                onChange={(e) =>
                setEditedData({ ...editedData, mapLink: e.target.value })
                }
                />


                  <Box display='flex' flexDirection='row'> 
                    <Button onClick={handleClick}>Update</Button>
                    <Button onClick={Cancel} color='error'>Cancel</Button>
                  </Box>
                </Stack>

              ) : (
                  <Stack>
                    <Box display='flex' flexDirection='row' alignContent='center' gap={1}>
                    <Stack spacing={1} textAlign='left' mt={1}>
                    <Typography variant='subtitle2'><strong>Address: </strong>{data?.contactAddress}</Typography>
                      <Typography variant='subtitle2'><strong>Phone: </strong>{data?.contactPhone}</Typography>
                      <Typography variant='subtitle2'><strong>Email: </strong>{data?.contactMail}</Typography>
                      <Typography variant='subtitle2' sx={{wordWrap:'break-word', wordBreak:'break-word'}}><strong>Map: </strong>{data?.mapLink}</Typography>
                    </Stack>
                      <Box display='flex' flexDirection='column' p={1}>
                        <IconButton
                          onClick={() => handleEditClick(data?.contactUsId)}
                          color="primary"
                          sx={{ marginTop: 1 }}
                        >
                          <EditIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Stack>
              )}
            </Grid>

        </Grid>
      </Container>
    </Box>
  )
}
