import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import { Box } from "@mui/material";
import { get_quote, get_transaction_movies } from "../../Api/Api";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";


export default function Query() {

   

    const options = {
      filterType: "dropdown",
      responsive: "scroll"
    };


    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [data , setData] = useState([]);
    const navigate = useNavigate();
    
    const getAllData = () => {
      axios({
        method: 'GET',
        url: get_quote,
      }).then(res => {
        if (!res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
            let temp = [];
            let dummy;
            res.data.data.map((data, index) => {
              dummy = {
                id: index + 1,
                names: data.names,
                email: data.email,
                subjects: data.subjects,
                messages: data.messages,
              };
              temp.push(dummy);
              return null;
            });
            setData(temp);
        } else {
          setOpen(true)
          setStatus(false)
          setColor(false)
          setMessage(res.data.message)
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
      });
    }
  
    useEffect(() => {
      getAllData();
    }, []);
  

    const columns = [
      {
        name: "id",
        label: "S.No",
        options: {
          filter: false,
          customBodyRender: (value, meta) => {
            return meta.rowIndex + 1;
          },
        },
      },
      {
        name: "names",
        label: "Name",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
                <div>
                <div>{value ? value : "-"}</div>
              </div>
            );
          },
        },
      },
      {
        name: "email",
        label: "Email",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                <div>{value ? value : "-"}</div>
              </div>
            );
          },
        },
      },
      {
        name: "subjects",
        label: "Subjects",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                <div>{value ? value : "-"}</div>
              </div>
            );
          },
        },
      },
      {
        name: "messages",
        label: "Messages",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                <div>{value ? value : "-"}</div>
              </div>
            );
          },
        },
      },
    ];



  return (
    <Box p={3}>
    {
    data.length !== 0 &&
    <MUIDataTable
    title={"Queries"}
    data={data}
    columns={columns}
    options={options}
    />
    }
    </Box>

  )
}
