import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete, Pagination
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { add_blog_product_company, add_testimonial, delete_blog_product_company, delete_testimonial, get_blog_product_company, get_testimonial, update_blog_product_company, update_testimonial } from "../../Api/Api";
import SnackBar from "../../components/SnackBar";
import Heading from "../../components/Heading/Heading";

export default function NewBlog() {

   const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);

  const [editedData, setEditedData] = useState({
    profileName: "",
    profilePosition: "",
    profileImage: null,
    blogImage: null,
    profileDate:"",
    blogTitle:"",
    blogContent:"",
    type:null
  });

  const [newData, setNewData] = useState({
    profileName: "",
    profilePosition: "",
    profileImage: null,
    blogImage: null,
    profileDate:"",
    blogTitle:"",
    blogContent:"",
  });

  const [newImage, setNewImage] = useState(null);
  const itemsPerPage = 2;

  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = React.useState(0);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  const AddNew = () =>{
    if(editedData.blogTitle !== '' && editedData.blogContent !== '' && editedData.blogImage !== null) {
    const serverData = new FormData()
    serverData.append("profileName", editedData.profileName) 
    serverData.append("profilePosition", editedData.profilePosition) 
    serverData.append("profileImage", editedData.profileImage)
    serverData.append("blogImage", editedData.blogImage)
    serverData.append("profileDate", editedData.profileDate)
    serverData.append("blogTitle", editedData.blogTitle) 
    serverData.append("blogContent", editedData.blogContent) 
    serverData.append("type", editedData.type) 
    axios({
      method: "POST",
      url: add_blog_product_company,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setNewImage(null);
        document.getElementById('newsliderImage').value ='';
        document.getElementById('newsliderImage1').value ='';
        setEditedData({
            profileName: "",
            profilePosition: "",
            profileImage: null,
            blogImage: null,
            profileDate:"",
            blogTitle:"",
            blogContent:"",
            type:null
        });
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }
    else{
      setOpen(true)
        setStatus(false)
        setColor(false)
      setMessage('Fill all the Fields');
    }
  }

  const maxLength = 300; // Set the maximum length you want to display

  // Function to truncate the text
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };


  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />



      <Container>
        <Box  mt={5} mb={2}>
        <Heading title={'Create New Blog'} />
      </Box>

        <Grid container spacing={2}>

        <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
        <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size='medium'
                      fullWidth
                      value={editedData.type}
                      onChange={(index, value) => setEditedData({ ...editedData, type:value })}
                      options={['Product', 'Company']}
                      renderInput={(params) => <TextField label='Blog Type' {...params} required size='small' />}
                    />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
            <TextField
            label="Profile Name"
            fullWidth
            variant="outlined"
            value={editedData.profileName}
            onChange={(e) =>
            setEditedData({ ...editedData, profileName: e.target.value })
            }
            />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>

            <TextField
            type="file"
            label='Profile Image'
            id='newsliderImage'
            fullWidth
            InputLabelProps={{
            shrink:true
            }}
            onChange={(event)=>   setEditedData({ ...editedData, profileImage: event.target.files[0] })}
            inputProps={{ accept: "image/*" }}
            sx={{ marginTop: 2 }}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
            <TextField
            label="Udpated On"
            fullWidth
            type="date"
            InputLabelProps={{
                shrink:true
                }}
            variant="outlined"
            value={editedData.profileDate}
            onChange={(e) =>
            setEditedData({ ...editedData, profileDate: e.target.value })
            }
            />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
            <TextField
            label="Blog For"
            fullWidth
            variant="outlined"
            value={editedData.profilePosition}
            onChange={(e) =>
            setEditedData({ ...editedData, profilePosition: e.target.value })
            }
            />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>

            <TextField
            label="Blog Title"
            fullWidth
            variant="outlined"
            value={editedData.blogTitle}
            onChange={(e) =>
            setEditedData({ ...editedData, blogTitle: e.target.value })
            }
            />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
            <TextField
            type="file"
            fullWidth
            label='Blog Image'
            id='newsliderImage1'
            InputLabelProps={{
            shrink:true
            }}
            onChange={(event)=>   setEditedData({ ...editedData, blogImage: event.target.files[0] })}
            inputProps={{ accept: "image/*" }}
            sx={{ marginTop: 2 }}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
            <TextField
            label="Blog Content"
            fullWidth
            variant="outlined"
            InputLabelProps={{
            shrink:true
            }}
            multiline
            rows={15}
            value={editedData.blogContent}
            onChange={(e) =>
            setEditedData({ ...editedData, blogContent: e.target.value })
            }
            />
            </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} display='flex' justifyContent='start'>
        <Box display='flex' flexDirection='row'> 
          <Button 
          variant="contained" onClick={AddNew}>Create New Blog</Button>
        </Box>
        </Grid>
        </Grid>


      </Container>



    </Box>
  )
}
