import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { add_faq, delete_faq, get_blog_news1, get_blog_news2, get_blog_title, get_faq, get_home_section1, get_home_section2, get_testimonial, update_blog_news1, update_blog_news2, update_faq, update_home_section1, update_home_section2 } from "../../Api/Api";
import SnackBar from "../../components/SnackBar";
import Heading from "../../components/Heading/Heading";

export default function Section2() {

   const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);
  const [titleList , setTitleList] = useState([]);
  const [headEdit , setHeadEdit] = useState('');

  const [editedData, setEditedData] = useState({
    id: "",
    profilePosition: "",
    blogImage: null,
    heading:"",
    blogTitle:"",
  });

  const [newData, setNewData] = useState({
    id: "",
    profilePosition: "",
    blogImage: null,
    heading:"",
    blogTitle:"",
  });

  const [newImage, setNewImage] = useState(null);

  const handleEditClick = (id) => {
    setImageId(id);
    setEditMode(true);
    getParticularData(id);
  };

  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedImage(newImage);
  };


  const getAlltitle = () => {
    axios({
      method: 'GET',
      url: get_blog_title,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setTitleList(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setTitleList([])
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAlltitle();
  }, []);


  const getAllData = () => {
    const sendData = new FormData()
    sendData.append('blogNewsId2', '');
    sendData.append('section', 'Blog');
    axios({
      method: 'POST',
      url: get_blog_news2,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
        setEditedData({ ...editedData, heading: res.data.heading })
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllData();
  }, []);



  const getParticularData = (id) => {
    if(id !== "" && id !== undefined){
    const sendData = new FormData()
    sendData.append('blogNewsId2', id);
    sendData.append('section', 'Blog');
    axios({
      method: 'POST',
      url: get_blog_news2,
      data: sendData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setEditedData({
          id:res.data.data.id,
          profilePosition:res.data.data.profilePosition,
          blogImage:res.data.data.blogImage,
          heading:res.data.heading,
          blogTitle:res.data.data.blogTitle,
        })
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }
  }

  useEffect(() => {
    getParticularData();
  }, [imageId])



  const handleClick = () => {
    const serverData = new FormData()
    serverData.append("blogNewsId2", imageId)
    serverData.append('section', 'Blog');
    serverData.append('heading', editedData.heading);
    serverData.append('id', editedData.id);
    axios({
      method: "POST",
      url: update_blog_news2,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setImageId('');
        getAllData();
        setSelectedImage(null);
        setEditMode(false);
        setHeadEdit('');
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  const Cancel = () => {
    setSelectedImage(null);
    setEditMode(false);
  }

   const handleHeadEditClick = (item) =>{
    setHeadEdit(item)
   }

   const cancelHeadEdit = () => {
    setHeadEdit('');
   }


  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

      <Container>

            <Grid container spacing={2} mt={4}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
                {headEdit === 'Head' ? (
                <Stack spacing={1}>
                    <TextField
                    label="Heading"
                    fullWidth
                    multiline
                    rows={2}
                    variant="outlined"
                    value={editedData.heading}
                    onChange={(e) =>
                    setEditedData({ ...editedData, heading: e.target.value })
                    }
                    />
                   <Box display='flex' flexDirection='row'> 
                    <Button onClick={handleClick}>Update</Button>
                    <Button onClick={cancelHeadEdit} color='error'>Cancel</Button>
                  </Box>
                </Stack>
                ) : (
                    <Stack>
                    <Box display='flex' flexDirection='row' alignContent='center' gap={1}>
                    <Stack spacing={1} textAlign='left'>
                    <Typography variant='subtitle1' fontWeight={600}>Heading</Typography>
                    <Typography variant='subtitle2'>{editedData?.heading}</Typography>
                    </Stack>
                    <Box display='flex' flexDirection='column' p={1}>
                        <IconButton
                          onClick={() => handleHeadEditClick('Head')}
                          color="primary"
                          
                        >
                          <EditIcon sx={{ verticalAlign: 'top' }} />
                        </IconButton>
                      </Box>
                    </Box>
                    </Stack>
              )}
              </Grid>
            </Grid>
   

        <Grid container spacing={2} mt={4}>
        {data && data.map((image , index) => (
            <Grid item xs={12} sm={6} md={6} lg={6} key={image?.blogNewsId2}>
              {editMode && imageId === image?.blogNewsId2 ? (
                <Stack spacing={1}>


                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size='medium'
                    fullWidth
                    value={editedData.type}
                    onChange={(index, value) => setEditedData({ ...editedData, id:value.id })}
                    options={titleList}
                    getOptionLabel={(option)=>option.blog_title}
                    renderInput={(params) => <TextField label='Blogs' {...params} required size='small' />}
                    />
                   
                  <Box display='flex' flexDirection='row'> 
                    <Button onClick={handleClick}>Update</Button>
                    <Button onClick={Cancel} color='error'>Cancel</Button>
                  </Box>
                </Stack>

              ) : (
                  <Stack>
                    <Box display='flex' flexDirection='row' alignContent='center' gap={1}>
                    <Stack spacing={1} textAlign='left'>
                    <Typography variant='subtitle2'><strong>Blog For: </strong> {image?.profilePosition}</Typography>
                      <Typography variant='subtitle2'><strong>Blog Title: </strong>{image?.blogTitle}</Typography>
                    </Stack>
                      <Box display='flex' flexDirection='column' p={1}>
                        <IconButton
                          onClick={() => handleEditClick(image?.blogNewsId2)}
                          color="primary"
                          sx={{ marginTop: 1 }}
                        >
                          <EditIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Stack>
              )}
            </Grid>
       ))}
        </Grid>
      </Container>
    </Box>
  )
}
