import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { add_faq, delete_faq, get_faq, get_home_section1, get_pricing, get_testimonial, update_faq, update_home_section1, update_pricing, update_pricing_service } from "../../Api/Api";
import SnackBar from "../../components/SnackBar";
import Heading from "../../components/Heading/Heading";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export default function PricingCards({product}) {

  const [costEditMode, setCostEditMode] = useState(false);
  const [serviceEditMode, setServiceEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [PlanName , setPlanName] = useState(null);
  const [cost , setCost] = useState(null);
  const [Updatedservice , setUpdatedService] = useState(null);
  const [UpdatedserviceId , setUpdatedServiceId] = useState(null);
  const [EditId, setEditId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);

  const [error , setError] = useState(false)
  const [errorMSG , setErrorMSG] = useState('')
  const [subscribeType , setSubscribeType] = useState('Monthly');


  const getAllData = (subscribe) => {
    const sendData = new FormData()
    sendData.append('pricingId', '');
    sendData.append('subscriptionType', subscribe);
    sendData.append('productName', product);
    axios({
      method: 'POST',
      url: get_pricing,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllData('Monthly');
  }, []);



  const handleServiceEditClick = (id) => {
    setServiceEditMode(true);
    setEditId(id)
  };

  
  const handleCostEditClick = (id, plan) => {
    setCostEditMode(true);
    setEditId(id)
    setPlanName(plan)
  };

  const handleCostChange = (plan, value) =>{
    setPlanName(plan)
    if (value.match(/^[0-9]+$/) || value === ''){  
    setCost(value)
    setError(false)
    setErrorMSG('');
    }
    else{
     setError(true)
     setErrorMSG(`Numeric's only Allowed`)
    }
  }

  const handleServiceNameChange = (plan, id , value) =>{
   setPlanName(plan)
   setUpdatedServiceId(id)
   setUpdatedService(value)
  }

  const Cancel = () =>{
    getAllData();
    setEditId(null)
    setServiceEditMode(false);
    setCostEditMode(false);
  }

  const UpdateCost = (id) =>{
    if(!error && cost !== null){
    const serverData = new FormData()
    serverData.append("pricingId", id)
    serverData.append("pricingType", PlanName)
    serverData.append("cost", cost)
    serverData.append("pricingDescription", '')
    axios({
      method: "POST",
      url: update_pricing,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        getAllData(subscribeType);
        setEditId(null)
        setCostEditMode(false);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }
  else{
    setOpen(true)
    setStatus(false)
    setColor(false)
    setMessage(`Cannot Be Empty`)
  }
  }

  const UpdateService = () =>{
    if(Updatedservice !== null && UpdatedserviceId !== null){
    const serverData = new FormData()
    serverData.append("pricingServiceId", UpdatedserviceId)
    serverData.append("serviceName", Updatedservice)
    serverData.append("isAvailable", 1)
    axios({
      method: "POST",
      url: update_pricing_service,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        getAllData(subscribeType);
        setEditId(null)
        setServiceEditMode(false);
        setUpdatedServiceId(null)
        setUpdatedService(null)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }
  }


  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

      <Container>

      <Box display='flex' flexDirection='row' gap={5} mt={1} mb={5}>
      <Button 
      variant='contained' 
      onClick={()=>{
        setSubscribeType('Monthly')
        getAllData('Monthly')
      }}
      sx={{bgcolor: subscribeType === 'Monthly' ? '#135DA8' : '#CECECE' , borderRadius:'10px',  width:'20ch' , color:'#fff' , fontWeight:550 , textTransform : 'capitalize'}}>
      Monthly
      </Button>
      <Button 
      onClick={()=>{
        setSubscribeType('Yearly')
        getAllData('Yearly')
      }}
      variant='contained' 
       sx={{bgcolor: subscribeType === 'Yearly' ? '#135DA8' : '#CECECE' , borderRadius:'10px',  width:'20ch' , color:'#fff' , fontWeight:550 , textTransform : 'capitalize'}}>
        Yearly
      </Button>
      </Box>


        <Grid container rowSpacing={10} spacing={2}>
        {data.map((plan, index) => {
        const planName = Object.keys(plan)[0];
        const planData = plan[planName];
        return (
          <Grid item xs={12} md={6} key={index}>

            <h2>{planName}</h2>

            { (costEditMode && EditId === 'Cost' && PlanName === planName) ? (
              <Box my={2}>
                <TextField
                   size="small"
                  placeholder={planData?.cost}
                  error={error}
                  helperText={errorMSG}
                  onChange={(e) => handleCostChange(planName, e.target.value)}
                />
                <Button onClick={() => UpdateCost(planData.pricingId)}><CheckCircleIcon sx={{color:'green'}} /></Button>
                <Button onClick={Cancel}><CancelIcon sx={{color:'red'}} /></Button>
              </Box>
            ) : (
              <Box display='flex' flexDirection='row' gap={1} my={2}>
                <Typography>${planData?.cost} <span style={{ fontSize: '12px' }}>/{subscribeType === 'Monthly' ? 'month':'year'}</span></Typography>
                <Box>
                        <IconButton
                          onClick={() => handleCostEditClick('Cost' , planName)}
                          color="primary"
                        >
                          <EditIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                      </Box>
              </Box>
            )}

            <Stack spacing={3}> 
              {planData?.services?.map((service, serviceIndex) => (
                <Box key={serviceIndex}>                 
                  { (serviceEditMode && EditId === service.pricingServiceId) ? (
                    <Box my={2}>
                    <TextField
                      size="small"
                      sx={{width:'30ch'}}
                      placeholder={service?.serviceName}
                      onChange={(e) => handleServiceNameChange(planName, service.pricingServiceId , e.target.value)}
                    />
                        <Button onClick={() => UpdateService(planData.pricingId)}><CheckCircleIcon sx={{color:'green'}} /></Button>
                        <Button onClick={Cancel}><CancelIcon sx={{color:'red'}} /></Button>
                    </Box>
                  ) : (
                    <Box display='flex' flexDirection='row' gap={1}>
                    <Typography>{service?.serviceName}</Typography>
                    <Box>
                    <IconButton
                    onClick={() => handleServiceEditClick(service.pricingServiceId)}
                      color="primary"
                    >
                      <EditIcon sx={{ verticalAlign: 'middle' }} />
                    </IconButton>
                  </Box>
                  </Box>
                  )}

                </Box>
              ))}
            </Stack>

          </Grid>
        );
      })}



        </Grid>
      </Container>
    </Box>
  )
}
