import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import axios from 'axios';
import MainSection1 from './MainSection1';
import MainSection2 from './MainSection2';
import MainSection3 from './MainSection3';
import MainSection4 from './MainSection4';
import PricingCards from './PricingCards';
import FAQ from './FAQ';
import Testimonials from './Testimonials';
import Contact from './Contact';

export default function WebsiteTab() {

    const [value, setValue] = React.useState(0);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 2 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }


    return (
        <Container>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={4} mb={1}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="success"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="full width tabs example"
                >
                    <Tab
                        label="Home - Main Section 1"
                        sx={{
                            bgcolor: value === 0 && '#135AC8',
                            color: value === 0 ? '#fff' : '#000',
                            border: value === 0 && '1px solid silver',
                            borderRadius: value === 0 && '4px',
                            textTransform: 'none'
                        }}
                        {...a11yProps(0)} />
                    <Tab
                        label="Home - Main Section 2"
                        sx={{
                            bgcolor: value === 1 && '#135AC8',
                            color: value === 1 ? '#fff' : '#000',
                            border: value === 1 && '1px solid silver',
                            borderRadius: value === 1 && 1,
                            textTransform: 'none'
                        }}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Home - Main Section 3"
                        sx={{
                            bgcolor: value === 2 && '#135AC8',
                            color: value === 2 ? '#fff' : '#000',
                            border: value === 2 && '1px solid silver',
                            borderRadius: value === 2 && 2,
                            textTransform: 'none'
                        }}
                        {...a11yProps(2)}
                    />
                    <Tab
                        label="Home - Main Section 4"
                        sx={{
                            bgcolor: value === 3 && '#135AC8',
                            color: value === 3 ? '#fff' : '#000',
                            border: value === 3 && '1px solid silver',
                            borderRadius: value === 3 && 3,
                            textTransform: 'none'
                        }}
                        {...a11yProps(3)}
                    />
                        <Tab
                        label="FAQ"
                        sx={{
                        bgcolor: value === 4 && '#135AC8',
                        color: value === 4 ? '#fff' : '#000',
                        border: value === 4 && '1px solid silver',
                        borderRadius: value === 4 && 4,
                        textTransform: 'none'
                        }}
                        {...a11yProps(4)}
                        />

                        <Tab
                        label="Testimonials"
                        sx={{
                        bgcolor: value === 5 && '#135AC8',
                        color: value === 5 ? '#fff' : '#000',
                        border: value === 5 && '1px solid silver',
                        borderRadius: value === 5 && 5,
                        textTransform: 'none'
                        }}
                        {...a11yProps(5)}
                        />
                       <Tab
                       label="Contact"
                        sx={{
                            bgcolor: value === 6 && '#135AC8',
                            color: value === 6? '#fff' : '#000',
                            border: value === 6 && '1px solid silver',
                            borderRadius: value === 6 && 6,
                            textTransform: 'none'
                        }}
                        {...a11yProps(6)}
                    />
                </Tabs>
            </Box>

           

            <TabPanel value={value} index={0} >
               <MainSection1/>
            </TabPanel>

           
            <TabPanel value={value} index={1}>
            <MainSection2 />
            </TabPanel>

            <TabPanel value={value} index={2}>
            <MainSection3 />
            </TabPanel>


            <TabPanel value={value} index={3}>
            <MainSection4 />
            </TabPanel>

            <TabPanel value={value} index={4}>
            <FAQ/>
            </TabPanel>

            <TabPanel value={value} index={5}>
                <Testimonials />
            </TabPanel>

            <TabPanel value={value} index={6}>
                <Contact />
            </TabPanel>


        </Container>
    )
}
