import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { add_faq, delete_faq, get_ai_generate, get_faq, get_home_section1, get_product_title, get_testimonial, update_ai_generate, update_faq, update_home_section1 } from "../../Api/Api";
import SnackBar from "../../components/SnackBar";
import Heading from "../../components/Heading/Heading";

export default function MainSection4() {

   const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);
  const [options, setOptions] = useState([]);

  const [editedData, setEditedData] = useState({
  title: "",
  content: "",
  redirect:null,
  });

  const [newData, setNewData] = useState({
    title: "",
    content: "",
    redirect:null,
  });

  const [newImage, setNewImage] = useState(null);

  const handleEditClick = (id) => {
    setImageId(id);
    setEditMode(true);
    getParticularData(id);
  };

  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedImage(newImage);
  };


  const getAllData = () => {
    const sendData = new FormData()
    sendData.append('AIGenerateId', '');
    axios({
      method: 'POST',
      url: get_ai_generate,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllData();
  }, []);



  const getParticularData = (id) => {
    if(id !== "" && id !== undefined){
    const sendData = new FormData()
    sendData.append('AIGenerateId', id);
    axios({
      method: 'POST',
      url: get_ai_generate,
      data: sendData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setEditedData({
          title: res.data.data.title,
          content: res.data.data.content,
          redirect:res.data.data.redirect,
        })
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }
  }

  useEffect(() => {
    getParticularData();
  }, [imageId])



  const handleClick = () => {
    const serverData = new FormData()
    serverData.append("title", editedData.title)
    serverData.append("content", editedData.content)
    serverData.append("AIGenerateId", imageId)
    serverData.append("redirect", editedData.redirect)
    serverData.append("images", selectedImage)
    axios({
      method: "POST",
      url: update_ai_generate,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setImageId('');
        getAllData();
        setSelectedImage(null);
        setEditMode(false);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  const Cancel = () => {
    setSelectedImage(null);
    setEditMode(false);
  }

  const getAlltitle = () => {
    const sendData = new FormData()
    sendData.append('productName', 'POET')
    axios({
        method: 'POST',
        url: get_product_title,
        data:sendData
    }).then(res => {
        if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
        } else {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(true)
            setColor(true)
            setOptions(res.data.data);
        }
    }).catch(err => {
        alert('Oops something went wrong ' + err)
    });
}

useEffect(()=>{
  getAlltitle();
},[])

  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

      <Container>
        <Grid container spacing={2}>
          {data && data.map((image , index) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={image.AIGenerateId}>
              {editMode && imageId === image.AIGenerateId ? (
                <Stack spacing={2}>
                     <TextField
                    label="Heading"
                    fullWidth
                    multiline
                    rows={2}
                    variant="outlined"
                    value={editedData.title}
                    onChange={(e) =>
                      setEditedData({ ...editedData, title: e.target.value })
                    }
                  />
                  <TextField
                    label="Content"
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={editedData.content}
                    onChange={(e) =>
                      setEditedData({ ...editedData, content: e.target.value })
                    }
                  />

                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size='medium'
                      fullWidth
                      onChange={(index, value) => setEditedData({ ...editedData,redirect: value?.title})}
                      options={options}
                      getOptionLabel={(option)=>option.title}
                      renderInput={(params) => <TextField placeholder={editedData.redirect} {...params} required size='small' />}
                    />

                      <TextField
                    type="file"
                    onChange={handleImageChange}
                    inputProps={{ accept: "image/*" }}
                    sx={{ marginTop: 2 }}
                  />
                  <Box display='flex' flexDirection='row'> 
                    <Button onClick={handleClick}>Update</Button>
                    <Button onClick={Cancel} color='error'>Cancel</Button>
                  </Box>
                </Stack>

              ) : (
                  <Stack>
                    <Box display='flex' flexDirection='row' alignContent='center' gap={1} mt={1} >
                    <img
                        src={image.images}
                        style={{
                          width: 150,
                          height: 150,
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                      <Box display='flex' flexDirection='column' p={1}>
                        <IconButton
                          onClick={() => handleEditClick(image.AIGenerateId)}
                          color="primary"
                          sx={{ marginTop: 1 }}
                        >
                          <EditIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                      </Box>
                    </Box>
                    <Stack spacing={1} textAlign='left' mt={1}>
                    <Typography variant='subtitle2'><strong>{image.title}</strong> </Typography>
                      <Typography variant='subtitle2'>{image.content}</Typography>
                      <Typography variant='subtitle2'><strong>Redirect: </strong>{image.redirect}</Typography>
                    </Stack>
                  </Stack>
              )}
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}
