import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { add_testimonial, delete_testimonial, get_testimonial, update_testimonial } from "../../Api/Api";
import SnackBar from "../../components/SnackBar";
import Heading from "../../components/Heading/Heading";

export default function Testimonials() {

   const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);

  const [editedData, setEditedData] = useState({
  star: "",
  review: "",
  images: null,
  names:"",
  });

  const [newData, setNewData] = useState({
    star: "",
    review: "",
    images: null,
    names:"",
  });

  const [newImage, setNewImage] = useState(null);

  const handleEditClick = (id) => {
    setImageId(id);
    setEditMode(true);
    getParticularData(id);
  };

  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedImage(newImage);
  };


  const getAllData = () => {
    const sendData = new FormData()
    sendData.append('testimonialId', '');
    axios({
      method: 'POST',
      url: get_testimonial,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
        setNewData({
          star: "",
          review: "",
          images: null,
          names:"",
        });
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllData();
  }, []);



  const getParticularData = (id) => {
    if(id !== "" && id !== undefined){
    const sendData = new FormData()
    sendData.append('testimonialId', id);
    axios({
      method: 'POST',
      url: get_testimonial,
      data: sendData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setEditedData({
          star:res.data.data.star,
          review: res.data.data.review,
          images: res.data.data.images,
          names:res.data.data.names,
        })
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }
  }

  useEffect(() => {
    getParticularData();
  }, [imageId])



  const handleClick = () => {
    const serverData = new FormData()
    serverData.append("star", editedData.star) 
    serverData.append("review", editedData.review) 
    serverData.append("names", editedData.names)
    serverData.append("testimonialId", imageId)
    serverData.append("images", selectedImage)
    axios({
      method: "POST",
      url: update_testimonial,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setImageId('');
        getAllData();
        setSelectedImage(null);
        setEditMode(false);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  const Cancel = () => {
    setSelectedImage(null);
    setEditMode(false);
  }

  const DeleteData = (Imageid) => {
    if (Imageid !== "") {
      const sendData = new FormData()
      sendData.append('testimonialId', Imageid);
      axios({
        method: 'POST',
        url: delete_testimonial,
        data: sendData,
      }).then(res => {
        if (!res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
          getAllData();
        } else {
          setOpen(true)
          setStatus(false)
          setColor(false)
          setMessage(res.data.message)
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
      });
    }
  }


  const AddNew = () =>{
    if(newData.title !== '' && newData.content !== '' && newImage !== null) {
    const serverData = new FormData()
    serverData.append("star", newData.star) 
    serverData.append("review", newData.review) 
    serverData.append("names", newData.names)
    serverData.append("images", newImage)
    axios({
      method: "POST",
      url: add_testimonial,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setNewImage(null);
        document.getElementById('newsliderImage').value ='';
        getAllData();
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }
    else{
      setOpen(true)
        setStatus(false)
        setColor(false)
      setMessage('Fill all the Fields');
    }
  }


  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

      <Container>
        <Grid container spacing={2}>
          {data && data.map((image) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={image.testimonialId}>
              {editMode && imageId === image.testimonialId ? (
                <Stack spacing={2}>
                  <TextField
                    label="Rating"
                    fullWidth
                    placeholder="Rate (1 to 5 )"
                    variant="outlined"
                    value={editedData.star}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (newValue.match(/^[0-9]+$/)){
                        if(newValue < 5){
                        setEditedData({...editedData,   star: parseInt(newValue)});
                        }
                        else{
                          setEditedData({...editedData,   star: 5}); 
                        }
                      }
                      else{
                        setEditedData({...editedData,   star: ''});
                      } 
                    }}
                  />
                  <TextField
                    label="Name"
                    fullWidth
                    variant="outlined"
                    value={editedData.names}
                    onChange={(e) =>
                      setEditedData({ ...editedData, names: e.target.value })
                    }
                  />

                    <TextField
                    label="Review"
                    fullWidth
                    variant="outlined"
                    value={editedData.review}
                    multiline
                    rows={5}
                    onChange={(e) =>
                    setEditedData({ ...editedData, review: e.target.value })
                    }
                    />

                  <TextField
                    type="file"
                    onChange={handleImageChange}
                    inputProps={{ accept: "image/*" }}
                    sx={{ marginTop: 2 }}
                  />
                  <Box display='flex' flexDirection='row'> 
                    <Button onClick={handleClick}>Update</Button>
                    <Button onClick={Cancel} color='error'>Cancel</Button>
                  </Box>
                </Stack>

              ) : (
                  <Stack>
                    <Box display='flex' flexDirection='row' alignContent='center'>
                      <img
                        src={image.images}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                      <Box display='flex' flexDirection='column' p={1}>
                        <IconButton
                          onClick={() => handleEditClick(image.testimonialId)}
                          color="primary"
                          sx={{ marginTop: 1 }}
                        >
                          <EditIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>

                        <IconButton
                          onClick={() => DeleteData(image.testimonialId)}
                          color="error"
                          sx={{ marginTop: 1 }}
                        >
                          <DeleteIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>

                      </Box>
                    </Box>
                    <Stack spacing={1} textAlign='left' mt={1}>
                      <Typography variant='subtitle2'><strong>Rating: </strong> {image.star}</Typography>
                      <Typography variant='subtitle2'><strong> Name: </strong>{image.names}</Typography>
                      <Typography variant='subtitle2'><strong> Review: </strong>{image.review}</Typography>
                    </Stack>
                  </Stack>
              )}
            </Grid>
          ))}
        </Grid>

        <Box  mt={5} mb={2}>
        <Heading title={'Add New Testimonials'} />
      </Box>

        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={7} display='flex' justifyContent='start'>
        <TextField
                    label="Rating"
                    fullWidth
                    placeholder="Rate (1 to 5 )"
                    variant="outlined"
                    value={newData.star}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (newValue.match(/^[0-9]+$/)){                    
                        if(newValue < 5){
                          setNewData({ ...newData,  star: parseInt(newValue)});
                          }
                          else{
                            setNewData({ ...newData,  star:5}); 
                          }
                      }
                      else{
                        setNewData({ ...newData,    star: ''});
                      } 
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={7} display='flex' justifyContent='start'>
                  <TextField
                    label="Name"
                    fullWidth
                    variant="outlined"
                    value={newData.names}
                    onChange={(e) =>
                      setNewData({ ...newData,  names: e.target.value })
                    }
                  />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={7} display='flex' justifyContent='start'>
                    <TextField
                      type="file"
                      size="small"
                      fullWidth
                      id='newsliderImage'
                      onChange={(e)=>setNewImage(e.target.files[0])}
                      inputProps={{ accept: 'image/*' }}
                      sx={{ marginTop: 2 }}
                    />
              </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={7} display='flex' justifyContent='start'>
                    <TextField
                    label="Review"
                    fullWidth
                    variant="outlined"
                    value={newData.review}
                    multiline
                    rows={5}
                    onChange={(e) =>
                      setNewData({ ...newData, review: e.target.value })
                    }
                    />
                    </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} display='flex' justifyContent='start'>
        <Box display='flex' flexDirection='row'> 
          <Button 
          variant="contained" onClick={AddNew}>Generate</Button>
        </Box>
        </Grid>
        </Grid>

      </Container>
    </Box>
  )
}
