import React from 'react'
import { Typography } from '@mui/material';

function Heading({ title }) {
    return (
        <Typography
            variant="h4"
            sx={{ textAlign: 'left', fontWeight: 550, fontSize: 24, textTransform: 'uppercase', color: '#115AC6' }}
        >
            {title}
        </Typography>
    )
}

export default Heading