import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import SnackBar from "../../../components/SnackBar";
import { get_work_individual3, update_work_individual3 } from "../../../Api/Api";

export default function  IndiSection3() {

   const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);
  const [headEdit , setHeadEdit] = useState('');

  const [editedData, setEditedData] = useState({
    personName: "",
    personRole:"",
    content:"",
    images:null,
    heading:"",
    });
  
    const [newData, setNewData] = useState({
      personName: "",
      personRole:"",
      content:"",
      images:null,
      heading:"",
    });

  const [newImage, setNewImage] = useState(null);

  const handleEditClick = (id) => {
    setImageId(id);
    setEditMode(true);
    getParticularData(id);
  };

  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedImage(newImage);
  };


  const getAllData = () => {
    const sendData = new FormData()
    sendData.append('individualId3', '');
    axios({
      method: 'POST',
      url: get_work_individual3,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
        setEditedData({ ...editedData, heading: res.data.heading })
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllData();
  }, []);



  const getParticularData = (id) => {
    if(id !== "" && id !== undefined){
    const sendData = new FormData()
    sendData.append('individualId3', id);
    axios({
      method: 'POST',
      url: get_work_individual3,
      data: sendData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setEditedData({
          personName: res.data.data.personName,
          personRole:res.data.data.personRole,
          content:res.data.data.content,
          images:res.data.data.images,
          heading:res.data.heading,
        })
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }
  }

  useEffect(() => {
    getParticularData();
  }, [imageId])



  const handleClick = () => {
    const serverData = new FormData()
    serverData.append("personName", editedData.personName)
    serverData.append("personRole", editedData.personRole)
    serverData.append("content", editedData.content)
    serverData.append("individualId3", imageId)
    serverData.append("images", selectedImage)
    serverData.append('heading', editedData.heading);
    axios({
      method: "POST",
      url: update_work_individual3,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setImageId('');
        getAllData();
        setSelectedImage(null);
        setEditMode(false);
        setHeadEdit('');
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  const Cancel = () => {
    setSelectedImage(null);
    setEditMode(false);
  }

  const handleHeadEditClick = (item) =>{
    setHeadEdit(item)
   }

   const cancelHeadEdit = () => {
    setHeadEdit('');
   }



  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />


    

      <Container>


      <Grid container spacing={2} mt={4}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
                {headEdit === 'Head' ? (
                <Stack spacing={1}>
                    <TextField
                    label="Heading"
                    fullWidth
                    multiline
                    rows={2}
                    variant="outlined"
                    value={editedData.heading}
                    onChange={(e) =>
                    setEditedData({ ...editedData, heading: e.target.value })
                    }
                    />
                   <Box display='flex' flexDirection='row'> 
                    <Button onClick={handleClick}>Update</Button>
                    <Button onClick={cancelHeadEdit} color='error'>Cancel</Button>
                  </Box>
                </Stack>
                ) : (
                    <Stack>
                    <Box display='flex' flexDirection='row' alignContent='center' gap={1}>
                    <Stack spacing={1} textAlign='left'>
                    <Typography variant='subtitle1' fontWeight={600}>Heading</Typography>
                    <Typography variant='subtitle2'>{editedData?.heading}</Typography>
                    </Stack>
                    <Box display='flex' flexDirection='column' p={1}>
                        <IconButton
                          onClick={() => handleHeadEditClick('Head')}
                          color="primary"
                          
                        >
                          <EditIcon sx={{ verticalAlign: 'top' }} />
                        </IconButton>
                      </Box>
                    </Box>
                    </Stack>
              )}
              </Grid>
            </Grid>

        <Grid container spacing={2} mt={5}>
          {data && data.map((image) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={image.individualId3}>
              {editMode && imageId === image.individualId3 ? (
                <Stack spacing={2}>
                   <TextField
                    label="Person Name"
                    fullWidth
                    variant="outlined"
                    value={editedData.personName}
                    onChange={(e) =>
                      setEditedData({ ...editedData, personName: e.target.value })
                    }
                  />

                  <TextField
                  label="Person Role"
                  fullWidth
                  variant="outlined"
                  value={editedData.personRole}
                  onChange={(e) =>
                  setEditedData({ ...editedData, personRole: e.target.value })
                  }
                  />

                <TextField
                label="Content"
                fullWidth
                multiline
                rows={8}
                variant="outlined"
                value={editedData.content}
                onChange={(e) =>
                setEditedData({ ...editedData, content: e.target.value })
                }
                />

                  <TextField
                    type="file"
                    onChange={handleImageChange}
                    inputProps={{ accept: "image/*" }}
                    sx={{ marginTop: 2 }}
                  />
                  <Box display='flex' flexDirection='row'> 
                    <Button onClick={handleClick}>Update</Button>
                    <Button onClick={Cancel} color='error'>Cancel</Button>
                  </Box>
                </Stack>

              ) : (
                  <Stack>
                    <Box display='flex' flexDirection='row' alignContent='center'>
                      <img
                        src={image.images}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                      <Box display='flex' flexDirection='column' p={1}>
                        <IconButton
                          onClick={() => handleEditClick(image.individualId3)}
                          color="primary"
                          sx={{ marginTop: 1 }}
                        >
                          <EditIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>

                      </Box>
                    </Box>
                    <Stack spacing={1} textAlign='left' mt={1}>
                    <Typography variant='subtitle2'><strong>Person Name: </strong>{image?.personName}</Typography>
                    <Typography variant='subtitle2'><strong>Person Role: </strong>{image?.personRole}</Typography>
                      <Typography variant='subtitle2'><strong>Content: </strong>{image?.content}</Typography>
                    </Stack>
                  </Stack>
              )}
            </Grid>
          ))}
        </Grid>

      </Container>
    </Box>
  )
}
