import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { add_faq, delete_faq, get_faq, get_testimonial, update_faq } from "../../Api/Api";
import SnackBar from "../../components/SnackBar";
import Heading from "../../components/Heading/Heading";

export default function FAQ() {

   const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);

  const [editedData, setEditedData] = useState({
  title: "",
  content: "",
  });

  const [newData, setNewData] = useState({
    title: "",
    content: "",
  });

  const [newImage, setNewImage] = useState(null);

  const handleEditClick = (id) => {
    setImageId(id);
    setEditMode(true);
    getParticularData(id);
  };

  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedImage(newImage);
  };


  const getAllData = () => {
    const sendData = new FormData()
    sendData.append('faqId', '');
    axios({
      method: 'POST',
      url: get_faq,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
        setNewData({
          title: "",
          content: "",
        })
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllData();
  }, []);



  const getParticularData = (id) => {
    if(id !== "" && id !== undefined){
    const sendData = new FormData()
    sendData.append('faqId', id);
    axios({
      method: 'POST',
      url: get_faq,
      data: sendData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setEditedData({
          title: res.data.data.title,
          content: res.data.data.content,
        })
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }
  }

  useEffect(() => {
    getParticularData();
  }, [imageId])



  const handleClick = () => {
    const serverData = new FormData()
    serverData.append("title", editedData.title)
    serverData.append("content", editedData.content)
    serverData.append("faqId", imageId)
    axios({
      method: "POST",
      url: update_faq,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setImageId('');
        getAllData();
        setSelectedImage(null);
        setEditMode(false);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  const Cancel = () => {
    setSelectedImage(null);
    setEditMode(false);
  }

  const DeleteData = (Imageid) => {
    if (Imageid !== "") {
      const sendData = new FormData()
      sendData.append('faqId', Imageid);
      axios({
        method: 'POST',
        url: delete_faq,
        data: sendData,
      }).then(res => {
        if (!res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
          getAllData();
        } else {
          setOpen(true)
          setStatus(false)
          setColor(false)
          setMessage(res.data.message)
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
      });
    }
  }


  const AddNew = () =>{
    if(newData.title !== '' && newData.content !== '') {
    const serverData = new FormData()
    serverData.append("title", newData.title) 
    serverData.append("content", newData.content) 
    axios({
      method: "POST",
      url: add_faq,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        getAllData();
        setNewImage(null);
        document.getElementById('newsliderImage').value ='';
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }
    else{
      setOpen(true)
        setStatus(false)
        setColor(false)
      setMessage('Fill all the Fields');
    }
  }


  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

      <Container>
        <Grid container spacing={2}>
          {data && data.map((image , index) => (
            <Grid item xs={12} sm={6} md={6} lg={6} key={image.faqId} p={2}>
              {editMode && imageId === image.faqId ? (
                <Stack spacing={2}>
                     <TextField
                    label="Question"
                    fullWidth
                    variant="outlined"
                    value={editedData.title}
                    onChange={(e) =>
                      setEditedData({ ...editedData, title: e.target.value })
                    }
                  />
                  <TextField
                    label="Answer"
                    fullWidth
                    variant="outlined"
                    value={editedData.content}
                    onChange={(e) =>
                      setEditedData({ ...editedData, content: e.target.value })
                    }
                  />
                  <Box display='flex' flexDirection='row'> 
                    <Button onClick={handleClick}>Update</Button>
                    <Button onClick={Cancel} color='error'>Cancel</Button>
                  </Box>
                </Stack>

              ) : (
                  <Stack>
                    <Box display='flex' flexDirection='row' alignContent='center' gap={1}>
                    <Stack spacing={1} textAlign='left'>
                      <Typography>{index + 1}</Typography>
                    <Typography variant='subtitle2'><strong>Question: </strong> {image.title}</Typography>
                      <Typography variant='subtitle2'><strong>Answer: </strong>{image.content}</Typography>
                    </Stack>
                      <Box display='flex' flexDirection='column' p={1}>
                        <IconButton
                          onClick={() => handleEditClick(image.faqId)}
                          color="primary"
                          sx={{ marginTop: 1 }}
                        >
                          <EditIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>

                        <IconButton
                          onClick={() => DeleteData(image.faqId)}
                          color="error"
                          sx={{ marginTop: 1 }}
                        >
                          <DeleteIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>

                      </Box>
                    </Box>
                  </Stack>
              )}
            </Grid>
          ))}
        </Grid>

        <Box  mt={5} mb={2}>
        <Heading title={'Add New FAQ'} />
        </Box>

        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
        <TextField
          label="Question"
          fullWidth
          size="small"
           multiline
           rows={2}
          variant="outlined"
          value={newData.title}
          onChange={(e) =>
            setNewData({ ...newData, title: e.target.value })
          }
        />
        </Grid>
         <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
        <TextField
          label="Answer"
          fullWidth
          size="small"
          multiline
          rows={2}
          variant="outlined"
          value={newData.content}
          onChange={(e) =>
            setNewData({ ...newData, content: e.target.value })
          }
        />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
        <Box display='flex' flexDirection='row'> 
          <Button variant="contained" onClick={AddNew}>Generate</Button>
        </Box>
        </Grid>
        </Grid>

      </Container>
    </Box>
  )
}
