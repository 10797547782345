import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete, Pagination
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { add_blog_product_company, add_success_stories2, add_testimonial, delete_blog_product_company, delete_testimonial, get_blog_product_company, get_testimonial, update_blog_product_company, update_testimonial } from "../../../Api/Api";
import SnackBar from "../../../components/SnackBar";
import Heading from "../../../components/Heading/Heading";

export default function NewSuccess() {

   const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);

  const [editedData, setEditedData] = useState({
    types: "",
    title: "",
    content:"",
    dates:"",
    images:null,
  });

  const [newData, setNewData] = useState({
    types: "",
    title: "",
    content:"",
    dates:"",
    images:null,
  });

  const [newImage, setNewImage] = useState(null);
  const itemsPerPage = 2;

  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = React.useState(0);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  const AddNew = () =>{
    if(editedData.title !== '' && editedData.content !== '' && editedData.images !== null) {
    const serverData = new FormData()
    serverData.append("dates", editedData.dates)
    serverData.append("content", editedData.content)
    serverData.append("types", editedData.types)
    serverData.append("title", editedData.title) 
    serverData.append("images", editedData.images) 
    axios({
      method: "POST",
      url: add_success_stories2,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        document.getElementById('newsliderImage1').value ='';
        setEditedData({
          types: "",
          title: "",
          content:"",
          dates:"",
          images:null,
        });
        setNewImage(null);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }
    else{
      setOpen(true)
        setStatus(false)
        setColor(false)
      setMessage('Fill all the Fields');
    }
  }

  const maxLength = 300; // Set the maximum length you want to display

  // Function to truncate the text
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };


  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />



      <Container>
        <Box  mt={5} mb={3}>
        <Heading title={'Create New Success Story'} />
      </Box>

        <Grid container spacing={2}>


        <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
        <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size='medium'
                  fullWidth
                  value={editedData.types}
                  onChange={(index, value) => setEditedData({ ...editedData, types:value })}
                  options={['BLOG POST', 'CASE STUDY', 'EVENTS', 'REPORTS']}
                  renderInput={(params) => <TextField label='Type' {...params} required size='small' />}
                  />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
            <TextField
            label="Udpated On"
            fullWidth
            type="date"
            InputLabelProps={{
                shrink:true
                }}
            variant="outlined"
            value={editedData.dates}
            onChange={(e) =>
            setEditedData({ ...editedData, dates: e.target.value })
            }
            />
            </Grid>
           
            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>

            <TextField
            label="Title"
            fullWidth
            variant="outlined"
            value={editedData.title}
            onChange={(e) =>
            setEditedData({ ...editedData, title: e.target.value })
            }
            />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
            <TextField
            type="file"
            fullWidth
            label='Image'
            id='newsliderImage1'
            InputLabelProps={{
            shrink:true
            }}
            onChange={(event)=>   setEditedData({ ...editedData, images: event.target.files[0] })}
            inputProps={{ accept: "image/*" }}
            sx={{ marginTop: 2 }}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
            <TextField
            label="Content"
            fullWidth
            variant="outlined"
            InputLabelProps={{
            shrink:true
            }}
            multiline
            rows={15}
            value={editedData.content}
            onChange={(e) =>
            setEditedData({ ...editedData, content: e.target.value })
            }
            />
            </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} display='flex' justifyContent='start'>
        <Box display='flex' flexDirection='row'> 
          <Button 
          variant="contained" onClick={AddNew}>Create New Story</Button>
        </Box>
        </Grid>
        </Grid>


      </Container>



    </Box>
  )
}
