import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete, Pagination
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { add_blog_product_company, add_testimonial, delete_blog_product_company, delete_testimonial, delete_work_events_reports, get_blog_product_company, get_testimonial, get_work_events_reports, update_blog_product_company, update_testimonial, update_work_events_reports } from "../../../Api/Api";
import SnackBar from "../../../components/SnackBar";
import Heading from "../../../components/Heading/Heading";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MarkDownDialog from "../../../components/MarkDownDialog";

export default function EventSection2() {

   const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);

  const [editedData, setEditedData] = useState({
    profileName: "",
    profilePosition: "",
    profileImage: null,
    images: null,
    profileDate:"",
    title:"",
    content:"",
  });

  const [newData, setNewData] = useState({
    profileName: "",
    profilePosition: "",
    profileImage: null,
    images: null,
    profileDate:"",
    title:"",
    content:"",
  });

  const [newImage, setNewImage] = useState(null);
  const itemsPerPage = 5;

  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = React.useState(0);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const [openDialog, setOpenDialog ] = useState(false);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleEditClick = (id) => {
    setImageId(id);
    setEditMode(true);
    getParticularData(id);
  };



  const getAllData = () => {
    const sendData = new FormData()
    sendData.append('eventReportId', '');
    sendData.append('section', 'Events');
    axios({
      method: 'POST',
      url: get_work_events_reports,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
        setNewData({
            profileName: "",
            profilePosition: "",
            profileImage: null,
            images: null,
            profileDate:"",
            title:"",
            content:"",
        });
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllData();
  }, []);



  const getParticularData = (id) => {
    if(id !== "" && id !== undefined){
    const sendData = new FormData()
    sendData.append('eventReportId', id);
    sendData.append('section', 'Events');
    axios({
      method: 'POST',
      url: get_work_events_reports,
      data: sendData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setEditedData({
          images: res.data.data.images,
          profileDate:res.data.data.profileDate,
          title:res.data.data.title,
          content:res.data.data.content,
        })
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }
  }

  useEffect(() => {
    getParticularData();
  }, [imageId])



  const handleClick = () => {
    const serverData = new FormData()
    serverData.append("profileName", editedData.profileName) 
    serverData.append("profilePosition", editedData.profilePosition) 
    serverData.append("profileImage", editedData.profileImage)
    serverData.append("images", editedData.images)
    serverData.append("profileDate", editedData.profileDate)
    serverData.append("title", editedData.title) 
    serverData.append("content", editedData.content) 
    serverData.append("eventReportId", imageId) 
    serverData.append('section', 'Events');
    axios({
      method: "POST",
      url:update_work_events_reports,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        getAllData();
        setImageId('');
       setSelectedImage(null);
        setEditMode(false);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  const Cancel = () => {
    setSelectedImage(null);
    setEditMode(false);
  }

  const DeleteData = (Imageid) => {
    if (Imageid !== "") {
      const sendData = new FormData()
      sendData.append('eventReportId', Imageid);
      axios({
        method: 'POST',
        url: delete_work_events_reports,
        data: sendData,
      }).then(res => {
        if (!res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
          getAllData();
        } else {
          setOpen(true)
          setStatus(false)
          setColor(false)
          setMessage(res.data.message)
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
      });
    }
  }



  const maxLength = 300; // Set the maximum length you want to display

  // Function to truncate the text
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };


  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

   <MarkDownDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />

    <Box display='flex' justifyContent='end' onClick={()=>setOpenDialog(true)} sx={{cursor:'pointer'}}>
    <HelpOutlineIcon sx={{color:'#135AD8', cursor:'pointer'}} />
    </Box>

      <Container>
        <Grid container spacing={5}>
          {currentItems && currentItems.map((image) => (
            <Grid item xs={12} sm={12} md={12} lg={12} key={image.eventReportId} mt={2}>
              {editMode && imageId === image.eventReportId ? (
                <Stack spacing={2}>
                  {/* <TextField
                    label="Profile Name"
                    fullWidth
                    variant="outlined"
                    value={editedData.profileName}
                    onChange={(e) =>
                      setEditedData({ ...editedData, profileName: e.target.value })
                    }
                  /> */}

                    {/* <TextField
                    type="file"
                    label='Profile Image'
                    InputLabelProps={{
                    shrink:true
                    }}
                    onChange={(event)=>   setEditedData({ ...editedData, profileImage: event.target.files[0] })}
                    inputProps={{ accept: "image/*" }}
                    sx={{ marginTop: 2 }}
                    /> */}

                    <TextField
                    label="Udpated On"
                    fullWidth
                    type="date"
                    InputLabelProps={{
                        shrink:true
                        }}
                    variant="outlined"
                    value={editedData.profileDate}
                    onChange={(e) =>
                    setEditedData({ ...editedData, profileDate: e.target.value })
                    }
                    />

                    {/* <TextField
                    label="Blog For"
                    fullWidth
                    variant="outlined"
                    value={editedData.profilePosition}
                    onChange={(e) =>
                    setEditedData({ ...editedData, profilePosition: e.target.value })
                    }
                    /> */}

                        <TextField
                        label="Title"
                        fullWidth
                        variant="outlined"
                        value={editedData.title}
                        onChange={(e) =>
                        setEditedData({ ...editedData, title: e.target.value })
                        }
                        />

                        <TextField
                        type="file"
                        label='Image'
                        InputLabelProps={{
                        shrink:true
                        }}
                        onChange={(event)=>   setEditedData({ ...editedData, images: event.target.files[0] })}
                        inputProps={{ accept: "image/*" }}
                        sx={{ marginTop: 2 }}
                        />

                        <TextField
                        label="Content"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                            shrink:true
                            }}
                        multiline
                        rows={15}
                        value={editedData.content}
                        onChange={(e) =>
                        setEditedData({ ...editedData, content: e.target.value })
                        }
                        />



                  <Box display='flex' flexDirection='row'> 
                    <Button onClick={handleClick}>Update</Button>
                    <Button onClick={Cancel} color='error'>Cancel</Button>
                  </Box>
                </Stack>

              ) : (
                  <Stack>
                    <Box display='flex' flexDirection='row' alignContent='center'>
                      <img
                        src={image.images}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                      <Box display='flex' flexDirection='column' p={1}>
                        <IconButton
                          onClick={() => handleEditClick(image.eventReportId)}
                          color="primary"
                          sx={{ marginTop: 1 }}
                        >
                          <EditIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>

                        <IconButton
                          onClick={() => DeleteData(image.eventReportId)}
                          color="error"
                          sx={{ marginTop: 1 }}
                        >
                          <DeleteIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>

                      </Box>
                    </Box>
                    <Stack spacing={2} textAlign='left' mt={1}>
                    <Typography variant='subtitle2'><strong>Title: </strong>{image.title}</Typography>
                      <Typography variant='subtitle2'><strong>Updated on: </strong>{image.profileDate}</Typography>
                        <Typography variant='subtitle2'>
                        <strong>Event Content: </strong>
                        {truncateText(image.content, maxLength)}
                        </Typography>
                    </Stack>
                  </Stack>
              )}
            </Grid>
          ))}
        </Grid>
        <Box mt={4} display='flex' justifyContent='center'>
        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
        </Box>
      </Container>



    </Box>
  )
}
