import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { add_faq, delete_faq, get_faq, get_home_section1, get_home_section2, get_testimonial, update_faq, update_home_section1, update_home_section2 } from "../../Api/Api";
import SnackBar from "../../components/SnackBar";
import Heading from "../../components/Heading/Heading";

export default function MainSection2() {

   const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);

  const [editedData, setEditedData] = useState({
  title: "",
  list1:"",
  list2:"",
  list3:"",
  images:null,
  });

  const [newData, setNewData] = useState({
    title: "",
    list1:"",
    list2:"",
    list3:"",
    images:null,
  });

  const [newImage, setNewImage] = useState(null);

  const handleEditClick = (id) => {
    setImageId(id);
    setEditMode(true);
    getParticularData(id);
  };

  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedImage(newImage);
  };


  const getAllData = () => {
    const sendData = new FormData()
    sendData.append('homeSection2Id', '');
    axios({
      method: 'POST',
      url: get_home_section2,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllData();
  }, []);



  const getParticularData = (id) => {
    if(id !== "" && id !== undefined){
    const sendData = new FormData()
    sendData.append('homeSection2Id', id);
    axios({
      method: 'POST',
      url: get_home_section2,
      data: sendData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setEditedData({
          title: res.data.data.title,
          list1:res.data.data.list1,
          list2:res.data.data.list2,
          list3:res.data.data.list3,
          images:res.data.data.images,
        })
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }
  }

  useEffect(() => {
    getParticularData();
  }, [imageId])



  const handleClick = () => {
    const serverData = new FormData()
    serverData.append("title", editedData.title)
    serverData.append("content", editedData.content)
    serverData.append("homeSection2Id", imageId)
    axios({
      method: "POST",
      url: update_home_section2,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setImageId('');
        getAllData();
        setSelectedImage(null);
        setEditMode(false);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  const Cancel = () => {
    setSelectedImage(null);
    setEditMode(false);
  }



  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

      <Container>
        <Grid container spacing={2}>
          {data && data.map((image , index) => (
            <Grid item xs={12} sm={6} md={6} lg={6} key={image.homeSection2Id}>
              {editMode && imageId === image.homeSection2Id ? (
                <Stack spacing={1}>
                     <TextField
                    label="Title"
                    fullWidth
                    multiline
                    rows={2}
                    variant="outlined"
                    value={editedData.title}
                    onChange={(e) =>
                      setEditedData({ ...editedData, title: e.target.value })
                    }
                  />

                  <TextField
                  label="Line 1"
                  fullWidth
                  multiline
                  rows={2}
                  variant="outlined"
                  value={editedData.list1}
                  onChange={(e) =>
                  setEditedData({ ...editedData, list1: e.target.value })
                  }
                  />

                  <TextField
                  label="Line 2"
                  fullWidth
                  multiline
                  rows={2}
                  variant="outlined"
                  value={editedData.list2}
                  onChange={(e) =>
                  setEditedData({ ...editedData, list2: e.target.value })
                  }
                  />

                  <TextField
                 label="Line 3"
                  fullWidth
                  multiline
                  rows={2}
                  variant="outlined"
                  value={editedData.list3}
                  onChange={(e) =>
                  setEditedData({ ...editedData, list3: e.target.value })
                  }
                  />
                  
                  <TextField
                    type="file"
                    onChange={handleImageChange}
                    inputProps={{ accept: "image/*" }}
                    sx={{ marginTop: 2 }}
                  />

                  <Box display='flex' flexDirection='row'> 
                    <Button onClick={handleClick}>Update</Button>
                    <Button onClick={Cancel} color='error'>Cancel</Button>
                  </Box>
                </Stack>

              ) : (
                  <Stack>
                    <Box display='flex' flexDirection='row' alignContent='center' gap={1}>
                    <img
                        src={image.images}
                        style={{
                          width: 200,
                          height: 200,
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                      <Box display='flex' flexDirection='column' p={1}>
                        <IconButton
                          onClick={() => handleEditClick(image.homeSection2Id)}
                          color="primary"
                          sx={{ marginTop: 1 }}
                        >
                          <EditIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                      </Box>
                    </Box>
                    <Stack spacing={1} textAlign='left'>
                    <Typography variant='subtitle2'><strong>Title: </strong> {image.title}</Typography>
                      <Typography variant='subtitle2'><strong>Line 1: </strong>{image.list1}</Typography>
                      <Typography variant='subtitle2'><strong>Line 2: </strong> {image.list2}</Typography>
                      <Typography variant='subtitle2'><strong>Line 3: </strong>{image.list3}</Typography>
                    </Stack>
                  </Stack>
              )}
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}
