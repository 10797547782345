import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete, Pagination
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { add_blog_product_company, add_education_events_reports, add_testimonial, add_work_events_reports, delete_blog_product_company, delete_testimonial, get_blog_product_company, get_testimonial, update_blog_product_company, update_testimonial } from "../../../Api/Api";
import SnackBar from "../../../components/SnackBar";
import Heading from "../../../components/Heading/Heading";

export default function NewReport() {

   const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);

  const [editedData, setEditedData] = useState({
    profileName: "",
    profilePosition: "",
    profileImage: null,
    images: null,
    profileDate:"",
    title:"",
    content:"",
    section:null,
  });

  const [newData, setNewData] = useState({
    profileName: "",
    profilePosition: "",
    profileImage: null,
    images: null,
    profileDate:"",
    title:"",
    content:"",
    section:null,
  });

  const [newImage, setNewImage] = useState(null);
  const itemsPerPage = 2;

  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = React.useState(0);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  const AddNew = () =>{
    if(editedData.profileDate !== '' && editedData.title && editedData.content !== '' && editedData.images !== null) {
    const serverData = new FormData()
    serverData.append("profileName", editedData.profileName) 
    serverData.append("profilePosition", editedData.profilePosition) 
    serverData.append("profileImage", editedData.profileImage)
    serverData.append("images", editedData.images)
    serverData.append("profileDate", editedData.profileDate)
    serverData.append("title", editedData.title) 
    serverData.append("content", editedData.content) 
    serverData.append("eventReportId", imageId) 
    serverData.append('section', editedData.section);
    axios({
      method: "POST",
      url: add_education_events_reports,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        document.getElementById('newsliderImage1').value ='';
        setEditedData({
          profileName: "",
          profilePosition: "",
          profileImage: null,
          images: null,
          profileDate:"",
          title:"",
          content:"",
          section:null,
      });       
        setNewImage(null);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }
    else{
      setOpen(true)
        setStatus(false)
        setColor(false)
      setMessage('Fill all the Fields');
    }
  }

  const maxLength = 300; // Set the maximum length you want to display

  // Function to truncate the text
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };


  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />



      <Container>
        <Box  mt={5} mb={3}>
        <Heading title={'Create New Events & Reports'} />
      </Box>

        <Grid container spacing={2}>

        <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
        <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size='medium'
                      fullWidth
                      value={editedData.section}
                      onChange={(index, value) => setEditedData({ ...editedData, section:value })}
                      options={['Events', 'Reports']}
                      renderInput={(params) => <TextField label='Type' {...params} required size='small' />}
                    />
            </Grid>

            {/* <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
            <TextField
            label="Profile Name"
            fullWidth
            variant="outlined"
            value={editedData.profileName}
            onChange={(e) =>
            setEditedData({ ...editedData, profileName: e.target.value })
            }
            />
            </Grid> */}

            {/* <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>

            <TextField
            type="file"
            label='Profile Image'
            id='newsliderImage'
            fullWidth
            InputLabelProps={{
            shrink:true
            }}
            onChange={(event)=>   setEditedData({ ...editedData, profileImage: event.target.files[0] })}
            inputProps={{ accept: "image/*" }}
            sx={{ marginTop: 2 }}
            />
            </Grid> */}

            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
            <TextField
            label="Udpated On"
            fullWidth
            type="date"
            InputLabelProps={{
                shrink:true
                }}
            variant="outlined"
            value={editedData.profileDate}
            onChange={(e) =>
            setEditedData({ ...editedData, profileDate: e.target.value })
            }
            />
            </Grid>

             {/* <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
            <TextField
            label="Blog For"
            fullWidth
            variant="outlined"
            value={editedData.profilePosition}
            onChange={(e) =>
            setEditedData({ ...editedData, profilePosition: e.target.value })
            }
            />
            </Grid> */}

            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>

            <TextField
            label="Title"
            fullWidth
            variant="outlined"
            value={editedData.title}
            onChange={(e) =>
            setEditedData({ ...editedData, title: e.target.value })
            }
            />
            </Grid> 

            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
            <TextField
            type="file"
            fullWidth
            label='Image'
            id='newsliderImage1'
            InputLabelProps={{
            shrink:true
            }}
            onChange={(event)=>   setEditedData({ ...editedData, images: event.target.files[0] })}
            inputProps={{ accept: "image/*" }}
            sx={{ marginTop: 2 }}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='start'>
            <TextField
            label="Content"
            fullWidth
            variant="outlined"
            InputLabelProps={{
            shrink:true
            }}
            multiline
            rows={15}
            value={editedData.content}
            onChange={(e) =>
            setEditedData({ ...editedData, content: e.target.value })
            }
            />
            </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} display='flex' justifyContent='start'>
        <Box display='flex' flexDirection='row'> 
          <Button 
          variant="contained" onClick={AddNew}>Create New {editedData?.section}</Button>
        </Box>
        </Grid>
        </Grid>


      </Container>



    </Box>
  )
}
