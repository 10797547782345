import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { add_faq, delete_faq, get_blog_news1, get_blog_title, get_faq, get_home_section1, get_home_section2, get_success_stories1, get_testimonial, update_blog_news1, update_faq, update_home_section1, update_home_section2, update_success_stories1 } from "../../../Api/Api";
import SnackBar from "../../../components/SnackBar";
import Heading from "../../../components/Heading/Heading";

export default function SuccessSection1() {

   const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);
  const [titleList , setTitleList] = useState([]);

  const [editedData, setEditedData] = useState({
    id: "",
    profilePosition: "",
    blogImage: null,
    heading:"",
    blogTitle:"",
  });

  const [newData, setNewData] = useState({
    id: "",
    profilePosition: "",
    blogImage: null,
    heading:"",
    blogTitle:"",
  });

  const [newImage, setNewImage] = useState(null);

  const handleEditClick = (id) => {
    setImageId(id);
    setEditMode(true);
    getParticularData(id);
  };

  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedImage(newImage);
  };


  const getAlltitle = () => {
    axios({
      method: 'GET',
      url: get_blog_title,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setTitleList(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setTitleList([])
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAlltitle();
  }, []);


  const getAllData = () => {
    const sendData = new FormData()
    sendData.append('successStoriesId1', '');
    axios({
      method: 'POST',
      url: get_success_stories1,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllData();
  }, []);



  const getParticularData = (id) => {
    if(id !== "" && id !== undefined){
    const sendData = new FormData()
    sendData.append('successStoriesId1', id);
    axios({
      method: 'POST',
      url: get_success_stories1,
      data: sendData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setEditedData({
          id: res.data.data.id,
          profilePosition:res.data.data.profilePosition,
          blogImage:res.data.data.blogImage,
          heading:res.data.data.heading,
          blogTitle:res.data.data.blogTitle,
        })
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }
  }

  useEffect(() => {
    getParticularData();
  }, [imageId])



  const handleClick = () => {
    const serverData = new FormData()
    serverData.append("blogNewsId", editedData?.id)
    serverData.append('successStoriesId1', imageId);
    axios({
      method: "POST",
      url: update_success_stories1,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setImageId('');
        getAllData();
        setSelectedImage(null);
        setEditMode(false);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  const Cancel = () => {
    setSelectedImage(null);
    setEditMode(false);
  }



  return (
    <Box>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

      <Container>
        <Grid container spacing={2}>

            <Grid item xs={12} sm={6} md={6} lg={6} key={data?.successStoriesId1}>
              {editMode && imageId === data?.successStoriesId1 ? (
                <Stack spacing={1}>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size='medium'
                    fullWidth
                    value={editedData.type}
                    onChange={(index, value) => setEditedData({ ...editedData, id:value.id })}
                    options={titleList}
                    getOptionLabel={(option)=>option.blog_title}
                    renderInput={(params) => <TextField label='Blogs' {...params} required size='small' />}
                    />
                   
                  <Box display='flex' flexDirection='row'> 
                    <Button onClick={handleClick}>Update</Button>
                    <Button onClick={Cancel} color='error'>Cancel</Button>
                  </Box>
                </Stack>

              ) : (
                  <Stack>
                    <Box display='flex' flexDirection='row' alignContent='center' gap={1}>
                    <img
                        src={data?.blogImage}
                        style={{
                          width: 200,
                          height: 200,
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                      <Box display='flex' flexDirection='column' p={1}>
                        <IconButton
                          onClick={() => handleEditClick(data?.successStoriesId1)}
                          color="primary"
                          sx={{ marginTop: 1 }}
                        >
                          <EditIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                      </Box>
                    </Box>
                    <Stack spacing={1} textAlign='left'>
                      <Typography variant='subtitle2'><strong>Blog Title: </strong>{data?.blogTitle}</Typography>
                      <Typography variant='subtitle2'><strong>Blog For: </strong> {data?.profilePosition}</Typography>
                    </Stack>
                  </Stack>
              )}
            </Grid>
    
        </Grid>
      </Container>
    </Box>
  )
}
