import React, { useState } from 'react';
import logo from '.././images/Group+571.png';
import {
  Drawer,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Collapse
} from '@mui/material';

import { Link } from 'react-router-dom'
import ResponsiveBd from '../ResponsiveBd';
// import ResponsiveBd from './ResponsiveBd';
import CategoryIcon from '@mui/icons-material/Category';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import BookIcon from '@mui/icons-material/Book';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

function SideBar({ setMobileOpen, mobileOpen }) {



  const responsive = ResponsiveBd();
  const drawerWidth = 280;
    const [openOne, setOpenOne] = useState(false);
    const [openTwo, setOpenTwo] = useState(false);
    const [openThree, setOpenThree] = useState(false);
    const [openFour, setOpenFour] = useState(false);
    const [openFive, setOpenFive] = useState(false);
    const [openSix, setOpenSix] = useState(false);
    const [openSeven, setOpenSeven] = useState(false);
    const [openEight, setOpenEight] = useState(false);
    const [openNine, setOpenNine] = useState(false);
    const [openTen, setOpenTen] = useState(false);
   const [openOrder, setOpenOrder] = useState(false);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
};

const handleClickOne = () => {
    setOpenOne(!openOne);
  };

  
const handleClickTwo = () => {
    setOpenTwo(!openTwo);
  };

  const handleClickThree = () => {
    setOpenThree(!openThree);
  };

  const handleClickFour = () => {
    setOpenFour(!openFour);
  };

  const handleClickFive = () => {
    setOpenFive(!openFive);
  };
  const handleClickSix = () => {
    setOpenSix(!openSix);
  };
  const handleClickSeven = () => {
    setOpenSeven(!openSeven);
  };
  const handleClickEight = () => {
    setOpenEight(!openEight);
  };
  const handleClickNine = () => {
    setOpenNine(!openNine);
  };
const handleClickTen = () => {
setOpenTen(!openTen);
};

const handleClickStoreOrders = () =>{
setOpenOrder(!openOrder);
}


  const drawer = ( 
    
      <Box sx={{ height: '100%' }}>
      {/* Logo */}
      <Box sx={{ px: 2 }}>
      <Box sx={{ textAlign: 'center' }}>
     <img className='dashlogo' src={logo} alt="logo" 
      style={{ objectFit: 'contain', height: '115px', width: '200px', verticalAlign:'top'}} loading='lazy' />
      </Box>
      </Box>



      <Link to="homepage" className={" text-nowrap text-decoration-none fs-5 "}>
        <ListItem button onClick={handleDrawerToggle}  sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: '#fff' }}>
        <ListItemIcon sx={{ px: 2, color: '#fff' }}><DashboardIcon sx={{color:'#fff'}} /></ListItemIcon>
        <ListItemText>
        <Typography sx={{ fontWeight: 500 }}>Home Page</Typography>
        </ListItemText>
        </ListItem>
        </Link>

        <ListItem onClick={handleClickOne} button sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: '#fff' }} >
        <ListItemIcon sx={{ px: 2, color: '#fff' }}><Inventory2Icon sx={{ color: '#fff' }} /></ListItemIcon>
          <ListItemText >
            <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
              <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}> Products</Box><Box>{openOne ? <ExpandLess /> : <ExpandMore />}</Box>
            </Typography>
          </ListItemText>
        </ListItem>
        <Collapse in={openOne} timeout="auto" unmountOnExit>
          <List sx={{ px: 2 }}>

       
          <Link to="poet" className=" text-nowrap text-decoration-none fs-5 ">
              <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: '#fff' }}>
                <ListItemIcon sx={{ px: 2, color: '#fff' }}>
                <AddBoxIcon sx={{color:'#FFDF15'}} />
                </ListItemIcon>
                <ListItemText sx={{ color: '#fff' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                   POET
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
           

           
          <Link to="contract" className=" text-nowrap text-decoration-none fs-5 ">
              <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: '#fff' }}>
                <ListItemIcon sx={{ px: 2, color: '#fff' }}>
                <EditRoadIcon sx={{color:'#FFDF15'}} />
                </ListItemIcon>
                <ListItemText sx={{ color: '#fff' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                    Contract-Pro
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
          

          </List>
        </Collapse>
   
     
        <Link to="about" className={" text-nowrap text-decoration-none fs-5 "}>
        <ListItem button onClick={handleDrawerToggle}  sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: '#fff' }}>
        <ListItemIcon sx={{ px: 2, color: '#fff' }}><BadgeOutlinedIcon sx={{color:'#fff'}} /></ListItemIcon>
        <ListItemText>
        <Typography sx={{ fontWeight: 500 }}>About Us</Typography>
        </ListItemText>
        </ListItem>
        </Link>

        <Link to="query" className={" text-nowrap text-decoration-none fs-5 "}>
        <ListItem button onClick={handleDrawerToggle}  sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: '#fff' }}>
        <ListItemIcon sx={{ px: 2, color: '#fff' }}><QueryBuilderIcon sx={{color:'#fff'}} /></ListItemIcon>
        <ListItemText>
        <Typography sx={{ fontWeight: 500 }}>Queries</Typography>
        </ListItemText>
        </ListItem>
        </Link>


        <ListItem onClick={handleClickTwo} button sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: '#fff' }} >
        <ListItemIcon sx={{ px: 2, color: '#fff' }}><WorkOutlineOutlinedIcon sx={{ color: '#fff' }} /></ListItemIcon>
          <ListItemText >
            <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
              <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}> For Work</Box><Box>{openTwo ? <ExpandLess /> : <ExpandMore />}</Box>
            </Typography>
          </ListItemText>
        </ListItem>
        <Collapse in={openTwo} timeout="auto" unmountOnExit>
          <List sx={{ px: 2 }}>

       
          <Link to="individual" className=" text-nowrap text-decoration-none fs-5 ">
              <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: '#fff' }}>
                <ListItemIcon sx={{ px: 2, color: '#fff' }}>
                <PortraitOutlinedIcon sx={{color:'#FFDF15'}} />
                </ListItemIcon>
                <ListItemText sx={{ color: '#fff' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                  Individuals
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
           

           
          <Link to="team" className=" text-nowrap text-decoration-none fs-5 ">
              <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: '#fff' }}>
                <ListItemIcon sx={{ px: 2, color: '#fff' }}>
                <WorkspacesOutlinedIcon sx={{color:'#FFDF15'}} />
                </ListItemIcon>
                <ListItemText sx={{ color: '#fff' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                  Teams & Businesses
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>

            <Link to="success" className=" text-nowrap text-decoration-none fs-5 ">
              <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: '#fff' }}>
                <ListItemIcon sx={{ px: 2, color: '#fff' }}>
                <CheckCircleOutlineOutlinedIcon sx={{color:'#FFDF15'}} />
                </ListItemIcon>
                <ListItemText sx={{ color: '#fff' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                  Success Stories
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>


            <Link to="workevent" className=" text-nowrap text-decoration-none fs-5 ">
              <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: '#fff' }}>
                <ListItemIcon sx={{ px: 2, color: '#fff' }}>
                <EventAvailableOutlinedIcon sx={{color:'#FFDF15'}} />
                </ListItemIcon>
                <ListItemText sx={{ color: '#fff' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                  Business Events
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
          

          </List>
        </Collapse>


        <ListItem onClick={handleClickThree} button sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: '#fff' }} >
        <ListItemIcon sx={{ px: 2, color: '#fff' }}><CastForEducationOutlinedIcon sx={{ color: '#fff' }} /></ListItemIcon>
          <ListItemText >
            <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
              <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}> For Education</Box><Box>{openThree ? <ExpandLess /> : <ExpandMore />}</Box>
            </Typography>
          </ListItemText>
        </ListItem>
        <Collapse in={openThree} timeout="auto" unmountOnExit>
          <List sx={{ px: 2 }}>

       
          <Link to="student" className=" text-nowrap text-decoration-none fs-5 ">
              <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: '#fff' }}>
                <ListItemIcon sx={{ px: 2, color: '#fff' }}>
                <SchoolOutlinedIcon sx={{color:'#FFDF15'}} />
                </ListItemIcon>
                <ListItemText sx={{ color: '#fff' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                  Students
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
           

           
          <Link to="institution" className=" text-nowrap text-decoration-none fs-5 ">
              <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: '#fff' }}>
                <ListItemIcon sx={{ px: 2, color: '#fff' }}>
                <LocationCityOutlinedIcon sx={{color:'#FFDF15'}} />
                </ListItemIcon>
                <ListItemText sx={{ color: '#fff' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                  Institutions
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>

            <Link to="educationevent" className=" text-nowrap text-decoration-none fs-5 ">
              <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: '#fff' }}>
                <ListItemIcon sx={{ px: 2, color: '#fff' }}>
                <EventAvailableOutlinedIcon sx={{color:'#FFDF15'}} />
                </ListItemIcon>
                <ListItemText sx={{ color: '#fff' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                  Education Events
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>

          </List>
        </Collapse>

        <Link to="blog" className={" text-nowrap text-decoration-none fs-5 "}>
        <ListItem button onClick={handleDrawerToggle}  sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: '#fff' }}>
        <ListItemIcon sx={{ px: 2, color: '#fff' }}><BookIcon sx={{color:'#fff'}} /></ListItemIcon>
        <ListItemText>
        <Typography sx={{ fontWeight: 500 }}>Blog</Typography>
        </ListItemText>
        </ListItem>
        </Link>

        <Link to="news" className={" text-nowrap text-decoration-none fs-5 "}>
        <ListItem button onClick={handleDrawerToggle}  sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: '#fff' }}>
        <ListItemIcon sx={{ px: 2, color: '#fff' }}><NewspaperIcon sx={{color:'#fff'}} /></ListItemIcon>
        <ListItemText>
        <Typography sx={{ fontWeight: 500 }}>News</Typography>
        </ListItemText>
        </ListItem>
        </Link>
  
  </Box>)
  return (
    <Box>
      <Drawer
        open={mobileOpen}
        variant={responsive ? 'temporary' : 'permanent'}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: 'block',
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            bgcolor: '#135AC8',
            color: '#4B4B4B'

          },
        }}
        anchor="left"
      >
        {drawer}
      </Drawer>
    </Box>
  )
}

export default SideBar;