import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { add_faq, delete_about_us, delete_faq, get_about_us, get_about_us_image, get_ai_generate, get_faq, get_home_section1, get_testimonial, update_about_us, update_about_us_image, update_ai_generate, update_faq, update_home_section1 } from "../../Api/Api";
import SnackBar from "../../components/SnackBar";
import Heading from "../../components/Heading/Heading";

export default function About() {

   const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [AboutimageId, setAboutImageId] = useState('');
  const [imageeditMode, setimageEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);
  const [ImageData , setImageData] = useState([]);

  const [editedData, setEditedData] = useState({
  title: "",
  content: "",
  section:"",
  });

  const [newData, setNewData] = useState({
    title: "",
    content: "",
  });

  const [newImage, setNewImage] = useState(null);

  const handleEditClick = (id) => {
    setImageId(id);
    setEditMode(true);
    getParticularData(id);
  };

  const handleImageEditClick = (id) => {
    setAboutImageId(id);
    setimageEditMode(true);
  };

  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedImage(newImage);
  };


  const getAllData = () => {
    const sendData = new FormData()
    sendData.append('aboutUsId', '');
    axios({
      method: 'POST',
      url: get_about_us,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllData();
  }, []);


  const getAllImageData = () => {
    const sendData = new FormData()
    sendData.append('aboutUsImageId', '');
    axios({
      method: 'POST',
      url: get_about_us_image,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setImageData(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllImageData();
  }, []);



  const getParticularData = (id) => {
    if(id !== "" && id !== undefined){
    const sendData = new FormData()
    sendData.append('aboutUsId', id);
    axios({
      method: 'POST',
      url: get_about_us,
      data: sendData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setEditedData({
          title: res.data.data.title,
          content: res.data.data.content,
          section:res.data.data.section,
        })
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }
  }

  useEffect(() => {
    getParticularData();
  }, [imageId])


  const handleClick = () => {
    const serverData = new FormData()
    serverData.append("title", editedData.title)
    serverData.append("content", editedData.content)
    serverData.append("aboutUsId", imageId)
    serverData.append("section", editedData.content)
    axios({
      method: "POST",
      url: update_about_us,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setImageId('');
        getAllData();
        setSelectedImage(null);
        setEditMode(false);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  const handleClickImage = () => {
    const serverData = new FormData()
    serverData.append("images", selectedImage)
    serverData.append("aboutUsImageId", AboutimageId)
    axios({
      method: "POST",
      url: update_about_us_image,
      data: serverData,
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setAboutImageId('');
        getAllImageData();
        setSelectedImage(null);
        setimageEditMode(false);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      console.log(err)
    });
  }


  const Cancel = () => {
    setSelectedImage(null);
    setEditMode(false);
  }

  const CancelImage = () => {
    setSelectedImage(null);
    setimageEditMode(false);
  }

  const DeleteData = (Imageid) => {
    if (Imageid !== "") {
      const sendData = new FormData()
      sendData.append('aboutUsId', Imageid);
      axios({
        method: 'POST',
        url: delete_about_us,
        data: sendData,
      }).then(res => {
        if (!res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
          getAllData();
        } else {
          setOpen(true)
          setStatus(false)
          setColor(false)
          setMessage(res.data.message)
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
      });
    }
  }




  return (
    <Box p={3}>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

      <Container>
        <Grid container spacing={7}>
          {data && data.map((image , index) => (
            <Grid item xs={12} sm={(index === 2 || index === 10) ? 7 : 6} md={(index === 2 || index === 10) ? 7 :6} lg={(index === 2 || index === 10) ? 7 :6} key={image.aboutUsId}>
              {editMode && imageId === image.aboutUsId ? (
                <Stack spacing={2}>
                     <TextField
                    label="Heading"
                    fullWidth
                    multiline
                    rows={2}
                    variant="outlined"
                    value={editedData.title}
                    onChange={(e) =>
                      setEditedData({ ...editedData, title: e.target.value })
                    }
                  />
                  <TextField
                    label="Content"
                    fullWidth
                    multiline
                    rows={10}
                    variant="outlined"
                    value={editedData.content}
                    onChange={(e) =>
                      setEditedData({ ...editedData, content: e.target.value })
                    }
                  />
                  <Box display='flex' flexDirection='row'> 
                    <Button onClick={handleClick}>Update</Button>
                    <Button onClick={Cancel} color='error'>Cancel</Button>
                  </Box>
                </Stack>

              ) : (
                <Stack>
                <Box display='flex' flexDirection='row' alignContent='center' gap={1}>
                <Stack spacing={1} textAlign='left'>
                <Typography variant='subtitle2'><strong>{image.title}</strong> </Typography>
                  <Typography variant='subtitle2'>{image.content}</Typography>
                </Stack>
                  <Box display='flex' flexDirection='column' p={1}>
                    <IconButton
                      onClick={() => handleEditClick(image.aboutUsId)}
                      color="primary"
                      sx={{ marginTop: 1 }}
                    >
                      <EditIcon sx={{ verticalAlign: 'middle' }} />
                    </IconButton>
                    
                    <IconButton
                          onClick={() => DeleteData(image.aboutUsId)}
                          color="error"
                          sx={{ marginTop: 1 }}
                        >
                          <DeleteIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                  </Box>
                </Box>
              </Stack>
              )}
            </Grid>
          ))}
        </Grid>
      </Container>

        <Container>
            <Grid container spacing={2} mt={4}>
            {ImageData && ImageData.map((image , index) => (
            <Grid item xs={12} sm={4} md={4} lg={4} key={image.aboutUsImageId}>
                {imageeditMode && AboutimageId === image.aboutUsImageId ? (
                <Stack spacing={1}>
                 <TextField
                    type="file"
                    onChange={handleImageChange}
                    inputProps={{ accept: "image/*" }}
                    sx={{ marginTop: 2 }}
                  />
                   <Box display='flex' flexDirection='row'> 
                    <Button onClick={handleClickImage}>Update</Button>
                    <Button onClick={CancelImage} color='error'>Cancel</Button>
                  </Box>
                </Stack>
                ) : (
                    <Stack>
                    <Box display='flex' flexDirection='row' alignContent='center' gap={1}>
                    <img
                        src={image.images}
                        style={{
                          width: 200,
                          height: 200,
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                      <Box display='flex' flexDirection='column' p={1}>
                        <IconButton
                          onClick={() => handleImageEditClick(image.aboutUsImageId)}
                          color="primary"
                          sx={{ marginTop: 1 }}
                        >
                          <EditIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Stack>
              )}
              </Grid>
            ))}
            </Grid>
        </Container>

    </Box>
  )
}
