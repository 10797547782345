const apibaseUrl = `https://mdqualityapps.in/API/pet/UAT/`;


const methodGet = 'GET';
const methodPost = 'POST';


const ProfileImageAPI = `${apibaseUrl}userprofile/`;

const login = `${apibaseUrl}login`;

const get_testimonial = `${apibaseUrl}get_testimonial`;

const get_user = `${apibaseUrl}get_user`;

const get_faq = `${apibaseUrl}get_faq`;

const update_faq = `${apibaseUrl}update_faq`;

const delete_faq = `${apibaseUrl}delete_faq`;

const add_faq = `${apibaseUrl}add_faq`;

const update_testimonial = `${apibaseUrl}update_testimonial`;

const add_testimonial = `${apibaseUrl}add_testimonial`;

const get_home_section1= `${apibaseUrl}get_home_section1`;

const update_home_section1 = `${apibaseUrl}update_home_section1`;

const get_home_section2 = `${apibaseUrl}get_home_section2`;

const update_home_section2 = `${apibaseUrl}update_home_section2`;

const get_home_section3 = `${apibaseUrl}get_home_section3`;

const update_home_section3 = `${apibaseUrl}update_home_section3`;

const get_ai_generate= `${apibaseUrl}get_ai_generate`;

const update_ai_generate = `${apibaseUrl}update_ai_generate`;

const get_pricing = `${apibaseUrl}get_pricing`;

const delete_testimonial = `${apibaseUrl}delete_testimonial`;

const get_product_title = `${apibaseUrl}get_product_title`;

const get_product = `${apibaseUrl}get_product`;

const update_product = `${apibaseUrl}update_product`;

const update_pricing = `${apibaseUrl}update_pricing`;

const update_pricing_service = `${apibaseUrl}update_pricing_service`;

const add_product = `${apibaseUrl}add_product`;

const delete_product = `${apibaseUrl}delete_product`;

const get_about_us = `${apibaseUrl}get_about_us`;

const delete_about_us = `${apibaseUrl}delete_about_us`;

const update_about_us = `${apibaseUrl}update_about_us`;

const get_about_us_image = `${apibaseUrl}get_about_us_image`;

const update_about_us_image = `${apibaseUrl}update_about_us_image`;

const get_work_individual1 = `${apibaseUrl}get_work_individual1`;

const get_work_individual2 = `${apibaseUrl}get_work_individual2`;

const get_work_individual3 = `${apibaseUrl}get_work_individual3`;

const update_work_individual1 = `${apibaseUrl}update_work_individual1`;

const update_work_individual2 = `${apibaseUrl}update_work_individual2`;

const update_work_individual3 = `${apibaseUrl}update_work_individual3`;

const update_teams_business1= `${apibaseUrl}update_teams_business1`;

const update_teams_business2 = `${apibaseUrl}update_teams_business2`;

const update_teams_business3= `${apibaseUrl}update_teams_business3`;

const update_teams_business4 = `${apibaseUrl}update_teams_business4`;

const get_teams_business1 = `${apibaseUrl}get_teams_business1`;

const get_teams_business2 = `${apibaseUrl}get_teams_business2`;

const get_teams_business3 = `${apibaseUrl}get_teams_business3`;

const get_teams_business4 = `${apibaseUrl}get_teams_business4`;

const get_success_stories1 = `${apibaseUrl}get_success_stories1`;

const get_success_stories2 = `${apibaseUrl}get_success_stories2`;

const update_success_stories1 = `${apibaseUrl}update_success_stories1`;

const update_success_stories2 = `${apibaseUrl}update_success_stories2`;

const get_events_reports1= `${apibaseUrl}get_events_reports1`;

const get_events_reports2= `${apibaseUrl}get_events_reports2`;

const get_events_reports3= `${apibaseUrl}get_events_reports3`;

const update_events_reports1= `${apibaseUrl}update_events_reports1`;

const update_events_reports2= `${apibaseUrl}update_events_reports2`;

const update_events_reports3= `${apibaseUrl}update_events_reports3`;

const get_student1 = `${apibaseUrl}get_student1`;

const get_student2 = `${apibaseUrl}get_student2`;

const get_student3 = `${apibaseUrl}get_student3`;

const get_student4 = `${apibaseUrl}get_student4`;

const update_student1 = `${apibaseUrl}update_student1`;

const update_student2 = `${apibaseUrl}update_student2`;

const update_student3 = `${apibaseUrl}update_student3`;

const update_student4 = `${apibaseUrl}update_student4`;

const get_institution1 = `${apibaseUrl}get_institution1`;

const get_institution2 = `${apibaseUrl}get_institution2`;

const get_institution3 = `${apibaseUrl}get_institution3`;

const get_institution4 = `${apibaseUrl}get_institution4`;

const get_institution5 = `${apibaseUrl}get_institution5`;

const update_institution1 = `${apibaseUrl}update_institution1`;

const update_institution2 = `${apibaseUrl}update_institution2`;

const update_institution3 = `${apibaseUrl}update_institution3`;

const update_institution4 = `${apibaseUrl}update_institution4`;

const update_institution5 = `${apibaseUrl}update_institution5`;

const get_education_events_reports1 = `${apibaseUrl}get_education_events_reports1`;

const get_education_events_reports2 = `${apibaseUrl}get_education_events_reports2`;

const get_education_events_reports3 = `${apibaseUrl}get_education_events_reports3`;

const update_education_events_reports1 = `${apibaseUrl}update_education_events_reports1`;

const update_education_events_reports2 = `${apibaseUrl}update_education_events_reports2`;

const update_education_events_reports3 = `${apibaseUrl}update_education_events_reports3`;

const get_contact_us = `${apibaseUrl}get_contact_us`;

const update_contact_us = `${apibaseUrl}update_contact_us`;

const get_blog_product_company = `${apibaseUrl}get_blog_product_company`;

const add_blog_product_company = `${apibaseUrl}add_blog_product_company`;

const update_blog_product_company = `${apibaseUrl}update_blog_product_company`;

const delete_blog_product_company = `${apibaseUrl}delete_blog_product_company`;

const get_news_product_company = `${apibaseUrl}get_news_product_company`;

const add_news_product_company = `${apibaseUrl}add_news_product_company`;

const update_news_product_company = `${apibaseUrl}update_news_product_company`;

const delete_news_product_company = `${apibaseUrl}delete_news_product_company`;

const get_blog_news1 = `${apibaseUrl}get_blog_news1`;

const update_blog_news1 = `${apibaseUrl}update_blog_news1`;

const get_blog_news2 = `${apibaseUrl}get_blog_news2`;

const update_blog_news2 = `${apibaseUrl}update_blog_news2`;

const get_blog_title = `${apibaseUrl}get_blog_title`;

const get_news_title = `${apibaseUrl}get_news_title`;

const delete_success_stories2 =`${apibaseUrl}delete_success_stories2`; 

const add_success_stories2 =`${apibaseUrl}add_success_stories2`; 

const update_work_events_reports = `${apibaseUrl}update_work_events_reports`; 

const delete_work_events_reports = `${apibaseUrl}delete_work_events_reports`; 

const get_work_events_reports = `${apibaseUrl}get_work_events_reports`; 

const add_work_events_reports = `${apibaseUrl}add_work_events_reports`; 


const update_education_events_reports = `${apibaseUrl}update_education_events_reports`; 

const delete_education_events_reports = `${apibaseUrl}delete_education_events_reports`; 

const get_education_events_reports = `${apibaseUrl}get_education_events_reports`; 

const add_education_events_reports = `${apibaseUrl}add_education_events_reports`; 

const get_quote = `${apibaseUrl}get_quote`; 


export { methodGet, methodPost, get_blog_news1,update_blog_news1,add_work_events_reports,delete_education_events_reports,
    get_work_events_reports, update_work_events_reports,delete_work_events_reports,update_education_events_reports,
    delete_success_stories2,add_success_stories2,get_education_events_reports,add_education_events_reports,
get_home_section1,get_product,get_blog_title,get_blog_news2  ,update_blog_news2, 
get_news_product_company,
delete_news_product_company, get_news_title,get_quote,
add_news_product_company,update_news_product_company, 
update_product,update_blog_product_company,delete_blog_product_company,
get_blog_product_company,add_blog_product_company,
get_ai_generate,update_ai_generate ,  update_home_section1,update_pricing,
get_home_section3, update_home_section3,get_product_title,update_pricing_service,
get_home_section2, update_home_section2,get_about_us_image,update_about_us_image,
get_user,update_testimonial, delete_testimonial,delete_product,get_about_us, 
add_testimonial,  ProfileImageAPI,add_product,delete_about_us,
login , get_testimonial ,get_faq ,update_about_us,
update_faq , add_faq, delete_faq , get_pricing,get_contact_us , update_contact_us,
get_work_individual1,
get_work_individual2,update_work_individual1,update_work_individual2,update_work_individual3,
get_work_individual3,
update_teams_business1,
update_teams_business2,
update_teams_business3,
update_teams_business4,
get_teams_business1,
get_teams_business2,
get_teams_business3,
get_teams_business4,
get_success_stories1,
get_success_stories2,
update_success_stories1,
update_success_stories2,
get_events_reports1,
get_events_reports2,
get_events_reports3,
update_events_reports1,
update_events_reports2,
update_events_reports3,
get_student1,
get_student2,
get_student3,
get_student4,
update_student1,
update_student2,
update_student3,
update_student4,
get_institution1,
get_institution2,
get_institution3,
get_institution4,
get_institution5,
update_institution1,
update_institution2,
update_institution3,
update_institution4,
update_institution5,
get_education_events_reports1,
get_education_events_reports2,
get_education_events_reports3,
update_education_events_reports1,
update_education_events_reports2,
update_education_events_reports3
    }